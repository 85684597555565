import { ViewComposition, ViewPresets } from '@codeleap/web'
import { variantProvider } from '../theme'

const createViewStyle = variantProvider.createVariantFactory<ViewComposition>()

export const AppViewStyles = {
  ...ViewPresets,
  default: createViewStyle((t) => ({
    wrapper: {
      display: 'flex',
    },
  })),
  separator: createViewStyle((theme) => ({
    wrapper: {
      width: '100%',
      height: 2,
      backgroundColor: theme.colors.neutral5,

      [theme.media.down('tabletSmall')]: {
        height: 4,
      },
    },
  })),
  'fullViewport': createViewStyle((theme) => ({
    wrapper: {
      width: '100vw',
      height: '100svh',
    },
  })),
  'pointerEvents:none': createViewStyle((theme) => ({
    wrapper: {
      pointerEvents: 'none',
    },
  })),
}
