import { retrieve } from './views'
import { useLocation } from '@reach/router'
import { QueryKeys } from './queryKeys'
import { AppStatus } from '@/redux'
import type Firebase from 'firebase/auth'
import { getFirebase } from '../../firebaseApp'
import { Profile } from '@/types'
import { AuthErrors } from './errors'
import { navigation } from '@/app'

const SCOPE = 'Social Authentication'

type FirebaseUser = Firebase.User

type SocialLoginReturn = {
  alreadyExistsInBackend: boolean
  user?: User | null
}

type SocialLoginResult = {
  customToken?: string
  credential?: Firebase.AuthCredential
  extraData?: Partial<FirebaseUser>
}

export type SocialProviderTypes = keyof typeof CredentialProviders

type User = Partial<Pick<Profile, 'first_name' | 'last_name' | 'email' | 'id'>>

export type TrySocialLoginArgs = {
  withProvider?: SocialProviderTypes
}

type SocialLoginOptions = {
  provider: SocialProviderTypes
  params?: any
  type?: 'login' | 'signup'
}

export const CredentialProviders = {
  google: async (): Promise<SocialLoginResult> => {
    try {
      const firebase = await getFirebase()
      const res = await firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())

      return {
        credential: res?.credential,
      }
    } catch (err) {
      logger.error({ err })
    }
  },
  facebook: async (): Promise<SocialLoginResult> => {
    try {
      const firebase = await getFirebase()
      const provider = new firebase.auth.FacebookAuthProvider()

      provider.addScope('email')
      provider.addScope('public_profile')

      provider.setCustomParameters({
        display: 'popup',
      })

      const res = await firebase.auth().signInWithPopup(provider)

      return {
        credential: res?.credential,
      }
    } catch (err) {
      logger.error({ err })
    }
  },
  apple: async (): Promise<SocialLoginResult> => {
    try {
      const firebase = await getFirebase()
      const provider = new firebase.auth.OAuthProvider('apple.com')

      provider.addScope('email')
      provider.addScope('name')

      const res = await firebase.auth().signInWithPopup(provider)

      return {
        credential: res?.credential,
      }
    } catch (err) {
      logger.error({ err })
    }
  },
}

export const authProvidersList = Object.keys(
  CredentialProviders,
) as SocialProviderTypes[]

export async function socialLogin(options: SocialLoginOptions): Promise<SocialLoginReturn> {
  try {
    const { provider, params = {}, type } = options

    AppStatus.set('loading')

    let firebaseUser: FirebaseUser = null

    let extraData: SocialLoginResult['extraData'] = null

    const firebase = await getFirebase()

    const socialCredential = await CredentialProviders[provider](params)

    extraData = socialCredential?.extraData

    let firebaseCredential = null

    if (!!socialCredential?.customToken) {
      firebaseCredential = await firebase.auth().signInWithCustomToken(socialCredential?.customToken)
    } else if (!!socialCredential?.credential) {
      firebaseCredential = await firebase.auth().signInWithCredential(socialCredential?.credential)
    }

    firebaseUser = firebaseCredential?.user

    let profile = null

    try {
      profile = await retrieve()
    } catch (e) {
      logger.info('Failed to get profile from API during socialLogin ' + provider, e, SCOPE)
    }

    const userExistsOnBackend = !!profile

    if (userExistsOnBackend) {
      await QueryKeys.me.refresh()
      AppStatus.authFinished()
      AppStatus.set('done')
      return {
        alreadyExistsInBackend: true,
        user: profile,
      }
    }

    if (firebaseUser) {
      const user: User = {
        id: firebaseUser?.uid,
        email: firebaseUser?.email,
      }

      if (provider === 'facebook') {
        user.email = firebaseUser?.providerData?.[0]?.email
      }

      const nameParts = (firebaseUser?.displayName || extraData?.displayName)?.split?.(' ') || ['', '']

      user.first_name = nameParts?.[0]

      if (nameParts.length > 1) {
        user.last_name = nameParts?.[1]
      }

      if (nameParts.length > 0) {
        // @ts-ignore
        user.name = nameParts?.join?.(' ')
      }

      if (type !== 'signup') AppStatus.set('idle')

      return {
        alreadyExistsInBackend: false,
        user: user,
      }
    }

    AppStatus.set('idle')

    return {
      alreadyExistsInBackend: false,
      user: null,
    }
  } catch (err) {
    AppStatus.set('idle')
    AuthErrors.onError(err)

    return {
      alreadyExistsInBackend: false,
      user: null,
    }
  }
}

export const useSocialLogin = (handle: (user: User, provider: SocialProviderTypes) => void, type: 'login' | 'signup') => {

  const location = useLocation()

  const isLogin = location?.pathname?.includes('login')

  const checkSocialAction = (socialResult: SocialLoginReturn) => {
    const { user, alreadyExistsInBackend } = socialResult

    if (isLogin && !alreadyExistsInBackend) {
      if (!!user?.id) {
        navigation.navigate('Auth.Signup')
      }

      return false
    } else if (!isLogin && alreadyExistsInBackend) {
      navigation.navigate('Home')
      return false
    }

    return true
  }

  const checkSocialResult = (socialResult: SocialLoginReturn, provider: SocialProviderTypes) => {
    const checked = checkSocialAction(socialResult)

    if (checked) {
      handle?.(socialResult?.user, provider)
    }
  }

  const handleSocial = async (provider: SocialProviderTypes) => {
    const socialResult = await socialLogin({ provider, type })
    checkSocialResult(socialResult, provider)
  }
  return handleSocial
}
