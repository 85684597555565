import { Theme, ThemeBreakpoint } from '@/app'
import { useMediaQuery } from '@codeleap/web'

export const useIsMobile = () => {
  const mediaQuery = Theme.media.down('mobile')

  const isMobile = useMediaQuery(mediaQuery, { getInitialValueInEffect: false })

  return isMobile
}

export const useIsTablet = () => {
  const mediaQuery = Theme.media.down('tabletSmall')

  const isTablet = useMediaQuery(mediaQuery, { getInitialValueInEffect: false })

  return isTablet
}

export const useMediaQueryDown = (breakpoint: ThemeBreakpoint) => {
  const mediaQuery = Theme.media.down(breakpoint)

  const isMobile = useMediaQuery(mediaQuery, { getInitialValueInEffect: false })

  return isMobile
}
