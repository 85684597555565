import { includePresets } from '@codeleap/common'
import { LARGE_BREAKPOINT, MID_BREAKPOINT, MOBILE_BREAKPOINT, Theme, variantProvider } from '@/app'

export type CenterWrapperComposition = 'wrapper' | 'innerWrapper'

const createCenterWrapperStyle =
  variantProvider.createVariantFactory<CenterWrapperComposition>()

const presets = includePresets((styles) => createCenterWrapperStyle(() => ({ innerWrapper: styles })))

const MAX_WIDTH = Theme.values.maxContentWidth

const safeHorizontalPaddings = Theme.safeHorizontalPaddings()

export const CenterWrapperStyles = {
  ...presets,
  default: createCenterWrapperStyle((theme) => ({
    wrapper: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      width: '100%',
    },
    innerWrapper: {
      flex: 1,
      display: 'flex',
      width: '100%',
      maxWidth: MAX_WIDTH,
      [theme.media.up('desktopHuge')]: {
        paddingLeft: safeHorizontalPaddings.desktopHuge,
        paddingRight: safeHorizontalPaddings.desktopHuge,
      },
      [theme.media.down('desktopLarge')]: {
        paddingLeft: safeHorizontalPaddings.desktopLarge,
        paddingRight: safeHorizontalPaddings.desktopLarge,
      },
      [theme.media.down('desktop')]: {
        paddingLeft: safeHorizontalPaddings.desktop,
        paddingRight: safeHorizontalPaddings.desktop,
      },
      [theme.media.down('laptop')]: {
        paddingLeft: safeHorizontalPaddings.laptop,
        paddingRight: safeHorizontalPaddings.laptop,
      },
      [theme.media.down('tablet')]: {
        paddingLeft: safeHorizontalPaddings.tablet,
        paddingRight: safeHorizontalPaddings.tablet,
      },
      [theme.media.down('tabletSmall')]: {
        paddingLeft: safeHorizontalPaddings.tabletSmall,
        paddingRight: safeHorizontalPaddings.tabletSmall,
      },
      [theme.media.down('mobile')]: {
        paddingLeft: safeHorizontalPaddings.mobile,
        paddingRight: safeHorizontalPaddings.mobile,
      },
    },
  })),
  sectionsPaddings: createCenterWrapperStyle((theme) => ({
    innerWrapper: {
      ...theme.spacing.paddingVertical(15),
      ...theme.spacing.gap(10),

      [theme.media.up('desktopHuge')]: {
        ...theme.spacing.paddingHorizontal(0),
      },

      [theme.media.down('desktopLarge')]: {
        ...theme.spacing.paddingHorizontal(0),
      },

      [theme.media.down(LARGE_BREAKPOINT)]: {
        ...theme.spacing.paddingVertical(10),
        ...theme.spacing.paddingHorizontal(8),
        ...theme.spacing.gap(8),
      },

      [theme.media.down(MID_BREAKPOINT)]: {
        ...theme.spacing.paddingVertical(10),
        ...theme.spacing.paddingHorizontal(8),
        ...theme.spacing.gap(6),
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.spacing.paddingVertical(5),
        ...theme.spacing.paddingHorizontal(0),
        ...theme.spacing.gap(4),
      },
    },
  })),
}
