import { View, Text, Link } from '@/components'
import { StylesOf } from '@codeleap/common'
import { FooterComposition } from '../../../app/stylesheets/Footer'
import { I18N, Settings } from '@/app'

export type FooterNavigateProps = {
  variantStyles?: StylesOf<FooterComposition>
}

type NavigateLink = {
  text: string
  route?: AppRoute
  to?: string
}

const links: Record<string, NavigateLink[]> = {
  pages: [
    { text: I18N.t('header.home'), route: 'Home' },
    { text: I18N.t('header.about'), route: 'About' },
    { text: I18N.t('header.contact'), route: 'Contact' },
  ],
  info: [
    { text: 'Help', to: 'http://docs.codeleap.co.uk/' },
    { text: 'Pricing', to: 'http://docs.codeleap.co.uk/' },
    { text: 'Blog', to: 'http://docs.codeleap.co.uk/' },
    { text: 'About Us', to: 'http://docs.codeleap.co.uk/' },
  ],
  legal: [
    { text: 'Privacy Policy', to: Settings.ContactINFO.PrivacyPolicy },
    { text: 'Terms & Conditions', to: Settings.ContactINFO.TermsAndConditions },
  ],
}

function Column({ links, title, variantStyles }) {
  const renderLink = (link, index) => (
    <Link
      key={index + 'footer-link'}
      route={link?.route}
      to={link?.to}
      openNewTab={!!link?.to}
      text={link?.text}
      css={variantStyles.navigateLink}
    />
  )

  return (
    <View css={variantStyles.navigateColumn}>
      <Text css={variantStyles.navigateTitle} text={title} />
      {links.map(renderLink)}
    </View>
  )
}

export const FooterNavigate = (props: FooterNavigateProps) => {
  const { variantStyles } = props

  return (
    <View css={variantStyles.navigateWrapper}>
      <Column
        variantStyles={variantStyles}
        title='Site'
        links={links?.pages}
      />
      <Column
        variantStyles={variantStyles}
        title='Info'
        links={links?.info}
      />
      <Column
        variantStyles={variantStyles}
        title='Legal'
        links={links?.legal}
      />
    </View>
  )
}
