import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'

import { Share } from './share'

export const shareOptions = {
  'whatsapp': {
    Action: WhatsappShareButton,
    color: '#25D366',
  },
  'mail': {
    Action: WhatsappShareButton,
    color: '#807B7A',
  },
  'facebook': {
    Action: FacebookShareButton,
    color: '#1877F2',
  },
  'linkedin': {
    Action: LinkedinShareButton,
    color: '#006699',
    provider: 'linkedin',
    parseUrl: (url: string) => {
      const origin = Share.getWebsite()
      const urlParts = url?.split(origin)
      return origin + '/' + urlParts?.[1]?.split('/')?.[1] + '/'
    },
  },
  'telegram': {
    Action: TelegramShareButton,
    color: '#29A9EB',
  },
  'twitter': {
    Action: TwitterShareButton,
    color: '#807B7A',
  },
  'instapapper': {
    Action: InstapaperShareButton,
    color: '#807B7A',
  },
  'tumblr': {
    Action: TumblrShareButton,
    color: '#807B7A',
  },
}
