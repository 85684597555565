import { LARGE_BREAKPOINT, MID_BREAKPOINT, MOBILE_BREAKPOINT, StyleConstants } from '@/app'
import { variantProvider } from '../theme'
import { assignTextStyle } from './Text'

export type AchievementCardComposition = 'wrapper' | 'number' | 'text'
export type AchievementsCardComposition = 'wrapper' | 'innerWrapper' | 'title' | `card${Capitalize<AchievementCardComposition>}` | 'icon'

const createAchievementsCardStyle = variantProvider.createVariantFactory<AchievementsCardComposition>()

export const AppAchievementsCardStyles = {
  default: createAchievementsCardStyle(theme => ({
    wrapper: {
      ...StyleConstants.orangeGradientInverted,
      ...theme.spacing.padding(6),
      ...theme.presets.row,
      ...theme.presets.justifySpaceBetween,
      borderRadius: theme.borderRadius.medium,
      ...theme.presets.alignCenter,
      ...theme.presets.relative,

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.presets.column,
        ...theme.spacing.gap(4),
        ...theme.spacing.padding(4),
      },
    },
    title: {
      ...assignTextStyle('h1')(theme).text,
      color: theme.colors.neutral1,

      [theme.media.down(LARGE_BREAKPOINT)]: {
        ...assignTextStyle('h2')(theme).text,
        color: theme.colors.neutral1,
      },
    },
    innerWrapper: {
      ...theme.presets.row,
      ...theme.spacing.gap(10),
      flex: 10,
      ...theme.presets.justifyEnd,

      [theme.media.down(LARGE_BREAKPOINT)]: {
        ...theme.spacing.gap(7),
      },

      [theme.media.down(MID_BREAKPOINT)]: {
        ...theme.spacing.gap(3),
      },
    },

    cardWrapper: {
      ...theme.presets.column,
    },
    cardNumber: {
      ...assignTextStyle('h0')(theme).text,
      color: theme.colors.neutral1,

      [theme.media.down(LARGE_BREAKPOINT)]: {
        ...assignTextStyle('h1')(theme).text,
        color: theme.colors.neutral1,
      },

      [theme.media.down(MID_BREAKPOINT)]: {
        ...assignTextStyle('h2')(theme).text,
        color: theme.colors.neutral1,
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...assignTextStyle('h3')(theme).text,
        color: theme.colors.neutral1,
      },
    },
    cardText: {
      ...assignTextStyle('h3')(theme).text,
      color: theme.colors.neutral1,
      whiteSpace: 'nowrap',

      [theme.media.down(LARGE_BREAKPOINT)]: {
        ...assignTextStyle('h4')(theme).text,
        color: theme.colors.neutral1,
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...assignTextStyle('p5')(theme).text,
        color: theme.colors.neutral1,
      },
    },

    icon: {
      ...theme.presets.absolute,
      ...theme.presets.right,
      ...theme.presets.bottom,
      ...theme.sized(17.5),

      [theme.media.down(LARGE_BREAKPOINT)]: {
        ...theme.sized(16.5),
      },

      [theme.media.down(MID_BREAKPOINT)]: {
        ...theme.sized(14.5),
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.sized(14),
      },
    },
  })),
}
