import { useState } from 'react'
import { AppTestimonialCarouselStyles } from '@/app/stylesheets'
import {
  ItemTestimonialCarouselComposition,
  TestimonialCarouselComposition,
} from '@/app/stylesheets/TestimonialCarousel'
import { Avatar, Icon, Pager, Text, View } from '@/components'
import { ComponentVariants, getNestedStylesByKey, useDefaultComponentStyle } from '@codeleap/common'
import { StylesOf } from '@codeleap/web'

type TestimonialProps = {
  name: string
  avatar: string
  job: string
  testimonial: string
  styles?: StylesOf<ItemTestimonialCarouselComposition>
}

type TestimonialCarouselProps = ComponentVariants<typeof AppTestimonialCarouselStyles> & {
  styles?: StylesOf<TestimonialCarouselComposition>
  testimonials: TestimonialProps[]
}

function Testimonial({ avatar, name, job, testimonial, styles }: TestimonialProps) {
  return (
    <View css={styles.wrapper}>
      <View css={styles.innerWrapper}>
        <Avatar
          image={avatar}
          name={name}
          debugName={`${name}-avatar`}
          styles={getNestedStylesByKey('avatar', styles)}
        />
        <View css={styles.nameWrapper}>
          <Text css={styles.name} text={name} />
          <Text css={styles.job} text={job} />
        </View>
      </View>
      <Text css={styles.testimonial} text={testimonial} />
      <Icon name='double-quote' style={styles.quote} debugName='quote' />
    </View>
  )
}

export function TestimonialCarousel({ testimonials, variants, responsiveVariants, styles }: TestimonialCarouselProps) {
  const [currentTestimonial, setCurrentTestimonial] = useState(0)

  const variantStyles = useDefaultComponentStyle<'u:TestimonialCarousel', typeof AppTestimonialCarouselStyles>(
    'u:TestimonialCarousel',
    {
      variants,
      responsiveVariants,
      styles,
    },
  )

  const itemStyles = getNestedStylesByKey('item', variantStyles)

  return (
    <Pager
      styles={getNestedStylesByKey('pager', variantStyles)}
      dots
      infinite
      adaptiveHeight={false}
      onChange={setCurrentTestimonial}
      dotsProps={{
        onPress: index => setCurrentTestimonial(index),
      }}
      page={currentTestimonial}>
      {testimonials.map((t, i) => (
        <Testimonial {...t} key={`testimonial-pages-${t.name}-${i}`} styles={itemStyles} />
      ))}
    </Pager>
  )
}
