import { LARGE_BREAKPOINT, MID_BREAKPOINT, MOBILE_BREAKPOINT } from '@/app'
import { PagerComposition } from '@codeleap/web'
import { variantProvider } from '../theme'
import { AvatarComposition } from './Avatar'
import { assignTextStyle } from './Text'

export type ItemTestimonialCarouselComposition =
  | 'wrapper'
  | 'innerWrapper'
  | 'nameWrapper'
  | 'name'
  | 'job'
  | 'testimonial'
  | 'quote'
  | `avatar${Capitalize<AvatarComposition>}`
export type TestimonialCarouselComposition =
  | `item${Capitalize<ItemTestimonialCarouselComposition>}`
  | `pager${Capitalize<PagerComposition>}`

const createTestimonialCarouselStyle = variantProvider.createVariantFactory<TestimonialCarouselComposition>()

export const AppTestimonialCarouselStyles = {
  default: createTestimonialCarouselStyle(theme => ({
    pagerDots: {
      ...theme.spacing.marginTop(5),
    },
    pagerDot: {
      ...theme.sized(1.5),
      backgroundColor: '#E5642D',
    },
    'pagerDot:selected': {
      ...theme.sized(1.5),
      backgroundColor: theme.colors.primary4,
    },

    itemWrapper: {
      borderRadius: theme.borderRadius.medium,
      ...theme.spacing.marginHorizontal('auto'),
      ...theme.presets.column,
      ...theme.presets.relative,
      backgroundColor: theme.colors.neutral1,
      ...theme.spacing.padding(6),
      ...theme.spacing.gap(4),
      maxWidth: theme.spacing.value(106),

      [theme.media.down(LARGE_BREAKPOINT)]: {
        maxWidth: theme.spacing.value(74),
      },

      [theme.media.down(MID_BREAKPOINT)]: {
        maxWidth: theme.spacing.value(70),
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.spacing.gap(3),
        ...theme.spacing.padding(2),
        borderRadius: theme.spacing.value(2),
        ...theme.spacing.marginHorizontal(2),
      },
    },
    itemInnerWrapper: {
      ...theme.presets.row,
      ...theme.spacing.gap(2),
      ...theme.presets.alignCenter,
    },
    itemAvatarWrapper: {
      ...theme.sized(10),

      [theme.media.down(LARGE_BREAKPOINT)]: {
        ...theme.sized(8),
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.sized(6),
      },
    },
    itemAvatarImage: {
      ...theme.sized(10),

      [theme.media.down(LARGE_BREAKPOINT)]: {
        ...theme.sized(8),
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.sized(6),
      },
    },
    itemAvatarInitials: {
      ...assignTextStyle('h1')(theme).text,
      color: theme.colors.neutral1,

      [theme.media.down(LARGE_BREAKPOINT)]: {
        ...assignTextStyle('h2')(theme).text,
        color: theme.colors.neutral1,
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...assignTextStyle('h3')(theme).text,
        color: theme.colors.neutral1,
      },
    },
    itemNameWrapper: {
      ...theme.presets.column,
    },
    itemName: {
      ...assignTextStyle('h2')(theme).text,
      color: theme.colors.primary2,

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...assignTextStyle('h5')(theme).text,
        color: theme.colors.primary2,
      },
    },
    itemJob: {
      ...assignTextStyle('p1')(theme).text,

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...assignTextStyle('p4')(theme).text,
      },
    },
    itemTestimonial: {
      ...assignTextStyle('h1')(theme).text,
      color: theme.colors.neutral8,

      [theme.media.down(LARGE_BREAKPOINT)]: {
        ...assignTextStyle('h3')(theme).text,
        color: theme.colors.neutral8,
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...assignTextStyle('p3')(theme).text,
        color: theme.colors.neutral8,
      },
    },
    itemQuote: {
      ...theme.presets.absolute,
      top: theme.spacing.value(4.5),
      right: theme.spacing.value(4.5),

      [theme.media.down(LARGE_BREAKPOINT)]: {
        top: theme.spacing.value(3.5),
        right: theme.spacing.value(3.5),
        ...theme.sized(8),
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        top: theme.spacing.value(2.5),
        right: theme.spacing.value(2.5),
        ...theme.sized(4),
      },
    },
  })),
}
