import React from 'react'
import { View, CenterWrapper } from '@/components'
import { useDefaultComponentStyle, ComponentVariants, StylesOf, PropsOf } from '@codeleap/common'
import { FooterComposition, AppFooterStyles } from '../../app/stylesheets/Footer'
import { FooterNavigate, FooterStores } from './Sections'

export type FooterProps =
  Omit<PropsOf<typeof CenterWrapper>, 'variants' | 'styles' | 'responsiveVariants'> &
  ComponentVariants<typeof AppFooterStyles> &
  {
    styles?: StylesOf<FooterComposition>
  }

const FooterComponent = (props: FooterProps) => {
  const { responsiveVariants, styles, variants, ...rest } = props

  const variantStyles = useDefaultComponentStyle<'u:Footer', typeof AppFooterStyles>('u:Footer', {
    responsiveVariants,
    styles,
    variants,
  })

  return (
    <CenterWrapper
      styles={{
        wrapper: variantStyles.wrapper,
        innerWrapper: variantStyles.innerWrapper,
      }}
      {...rest}
    >
      <View css={variantStyles.content}>
        <FooterStores />
        <FooterNavigate variantStyles={variantStyles} />
      </View>
    </CenterWrapper>
  )
}

export const Footer = React.memo(FooterComponent, () => true)
