import { AppImages, assignTextStyle, I18N, StyleConstants, variantProvider } from '@/app'
import { CenterWrapper, TestimonialCarousel, Text } from '@/components'

const testimonials = [
  {
    name: 'Lea Huber',
    avatar: null,
    job: 'Freelance Graphic Designer',
    testimonial:
      'I got a sneak peek of DooitApp, and I love the idea behind it! It’s so cool to have a platform that connects people.  The layout is super intuitive, making it easy to navigate. I can’t wait for it to launch!',
  },
  {
    name: 'Luca Schneider',
    avatar: null,
    job: 'Small Business Owner',
    testimonial:
      'DooitApp is a fantastic concept! It’s going to make finding help so much simpler. The interface is super friendly.',
  },
  {
    name: 'Sarah Müller',
    avatar: null,
    job: 'DooitApp requester',
    testimonial:
    'I’ve been testing DooitApp with some friends, and I’m really impressed!',
  },
  {
    name: 'Daniel Fischer',
    avatar: null,
    job: 'Community Organizer',
    testimonial:
      'Fantastic idea! I love how it brings together people who need help and those who can provide it. The app is easy to use, and I can already see how it will make life simpler for so many. Looking forward to seeing it go live!',
  },
]

export function TestimonialsSection() {
  return (
    <CenterWrapper
      variants={['sectionsPaddings']}
      styles={{
        wrapper: styles.centerWrapper,
        innerWrapper: styles.centerInnerWrapper,
      }}>
      <Text css={styles.text} text={I18N.t('homeSections.testimonials.title')} />
      <TestimonialCarousel testimonials={testimonials}/>
    </CenterWrapper>
  )
}

const styles = variantProvider.createComponentStyle(
  theme => ({
    centerWrapper: {
      ...StyleConstants.orangeGradient,
    },
    centerInnerWrapper: {
      ...theme.presets.column,
      ...theme.presets.center,
    },

    text: {
      ...assignTextStyle('h0')(theme).text,
      color: theme.colors.neutral1,
      ...theme.presets.fullWidth,
      ...theme.presets.textCenter,
    },
  }),
  true,
)
