import { View, Text } from '@codeleap/web'
import { Extensions, NodeViewWrapper, ReactNodeViewRenderer, mergeAttributes, Editor } from '@tiptap/react'
import { Node, Extension } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import Superscript from '@tiptap/extension-superscript'
import SubScript from '@tiptap/extension-subscript'
import FontFamily from '@tiptap/extension-font-family'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import Heading from '@tiptap/extension-heading'
import Link from '@tiptap/extension-link'
import TipTapImage from '@tiptap/extension-image'
import Placeholder from '@tiptap/extension-placeholder'

const CustomImageExt = TipTapImage.extend({
  addAttributes() {
    return {
      src: { default: null },
      'data-uploaded': { default: null },
      width: { default: null },
      height: { default: null },
      alt: { default: null },
      title: { default: null },
    }
  },
})

const FileComponent = (props) => {
  const attrs = props?.node?.attrs
  console.log(attrs?.file)
  return (
    <NodeViewWrapper className='fileComponent' data-uploaded={attrs?.['data-uploaded']}>
      <View>
        <Text variants={['h3']} text={`File: ${attrs?.file.file.name}`}/>
      </View>
    </NodeViewWrapper>
  )
}

const FileExt = Node.create({
  name: 'fileComponent',
  group: 'block',
  atom: true,
  addAttributes() {
    return { 'data-uploaded': { default: null }, file: { default: null }}
  },
  parseHTML() {
    return [{ tag: 'fileComponent' }]
  },
  renderHTML({ HTMLAttributes }) {
    return ['fileComponent', mergeAttributes(HTMLAttributes), 0]
  },
  /*@ts-ignore*/
  addCommands() {
    return {
      setAttachment: attributes => ({ commands }: Editor) => {
        return commands.insertContent({ type: 'fileComponent', attrs: attributes })
      },
    }
  },
  addNodeView() {
    return ReactNodeViewRenderer(FileComponent)
  },
})

export const TextEditorExtensions: Extensions = [
  Underline,
  Superscript,
  SubScript,
  TextStyle,
  CustomImageExt,
  FileExt,
  Placeholder.configure({ placeholder: 'Placeholder', emptyEditorClass: 'placeholder' }),
  Highlight.configure({ multicolor: true }),
  Heading.configure({ levels: [1, 2, 3, 4, 5, 6] }),
  TextAlign.configure({ types: ['heading', 'paragraph', 'image'] }),
  FontFamily.configure({ types: ['textStyle'] }),
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  Link.configure({
    protocols: ['mailto'],
    HTMLAttributes: { rel: 'noopener noreferrer', target: '_blank' },
    validate: (href) => /^(?:https?:\/\/|www\.)\S+/.test(href),
  }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
]
