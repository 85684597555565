import './services/firebaseApp'

import { StyleProvider, ReactQuery, I18NProvider, useI18N, onUpdate } from '@codeleap/common'
import { Settings, variantProvider, React, I18N } from './app'
import { AppStatusOverlay, DebugModal, GlobalAlert, GlobalStyle, Onboarding, ShareModal, variants } from './components'
import { Provider } from 'react-redux'
import { store } from './redux'
import { APIClient } from './services'
import { initialLocale } from './app/i18n'
import { getRootLocale } from './utils'

const App = ({ children }) => {
  APIClient.Session.useSession(true)

  return (
    <>
      <GlobalStyle />
      {children}
      <AppStatusOverlay />
      <GlobalAlert />
      <DebugModal />
      <Onboarding />
      <ShareModal />
    </>
  )
}

export const Root = ({ children }) => {
  const { setLocale, isSettingLocale } = useI18N()

  onUpdate(() => {
    const rootLocale = getRootLocale(initialLocale)
    setLocale(rootLocale)
  }, [initialLocale, isSettingLocale])

  return (
    <ReactQuery.QueryClientProvider client={APIClient.queryClient.client}>
      <Provider store={store}>
        <StyleProvider settings={Settings} variantProvider={variantProvider} variants={variants} logger={logger}>
          <I18NProvider i18n={I18N}>
            <App>{children}</App>
          </I18NProvider>
        </StyleProvider>
      </Provider>
    </ReactQuery.QueryClientProvider>
  )
}
