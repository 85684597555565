import React from 'react'
import { ComponentVariants, getNestedStylesByKey, GlobalAlertType, OSAlertStore, PropsOf, StylesOf, TypeGuards, useDefaultComponentStyle } from '@codeleap/common'
import { Modal, Text, View, Button } from '@/components'
import { AlertPresets, AlertComposition } from '@/app/stylesheets/Alert'
import { appStatusStore } from '@/utils'

type ButtonProps = Partial<Omit<PropsOf<typeof Button>, 'debugName'>>
type AlertType = 'info' | 'error' | 'warn' | 'destructive' | string

type AlertProps =
  Partial<PropsOf<typeof Modal>> &
  Partial<ActionButtonProps> &
  Partial<DismissButtonProps> &
  ComponentVariants<typeof AlertPresets> & {
    type: AlertType
    orientation?: 'row' | 'column'
    options?: ButtonProps[]
    styles?: StylesOf<AlertComposition>
    closeOnDismiss?: boolean
    closeOnAction?: boolean
    optionsOnly?: boolean
    autoClose?: boolean
    title: string
    body?: string
  }

type ActionButtonProps = {
  onAction?: () => void
  actionButtonProps?: ButtonProps
}

type DismissButtonProps = {
  onDismiss?: () => void
  dismissButtonProps?: ButtonProps
}

function verifyType(type: AlertType) {
  return {
    isInfo: type === 'info',
    isWarn: type === 'warn',
    isError: type === 'error',
    isDestructive: type === 'destructive',
  }
}

function ActionButton({ onAction, actionButtonProps, isDestructive }: ActionButtonProps & { isDestructive: boolean }) {
  const initialText = isDestructive ? 'Delete' : 'OK'
  return (
    <Button
      text={initialText}
      debugName={`${initialText} button from alert modal`}
      variants={['flex', isDestructive && 'destructive']}
      {...actionButtonProps}
      onPress={onAction}
    />
  )
}

function DismissButton({ onDismiss, dismissButtonProps }: DismissButtonProps) {
  return (
    <Button
      text='Dismiss'
      debugName='Dismiss button from alert modal'
      variants={['outline', 'flex']}
      {...dismissButtonProps}
      onPress={onDismiss}
    />
  )
}

export const Alert = (props: AlertProps) => {
  const {
    body,
    children,
    variants = [],
    styles,
    options = [],
    orientation = 'row',
    responsiveVariants = {},
    closeOnDismiss = true,
    closeOnAction = true,
    autoClose = true,
    type,
    onAction,
    actionButtonProps,
    dismissButtonProps,
    onDismiss,
    optionsOnly,
    ...modalProps
  } = props

  const { isWarn, isDestructive } = React.useMemo(() => verifyType(type), [type])

  const appStatus = appStatusStore(store => store.status)

  const variantStyles = useDefaultComponentStyle<'u:Alert', typeof AlertPresets>('u:Alert', {
    variants,
    responsiveVariants,
    styles,
  })

  const modalStyles = React.useMemo(() => {
    return getNestedStylesByKey('modal', variantStyles)
  }, [variantStyles])

  const isRow = orientation === 'row'

  const showActionButton = isWarn || isDestructive

  const hasOptions = options?.length > 0 && !!options

  const handleDismiss = () => {
    if (TypeGuards.isFunction(onDismiss)) onDismiss?.()
    if (closeOnDismiss) modalProps?.toggle?.(false)
  }

  const handleAction = () => {
    if (TypeGuards.isFunction(onAction)) onAction?.()
    if (closeOnAction) modalProps?.toggle?.(false)
  }

  const ActionComponent = React.useCallback(() => (
    <View variants={['flex']}>
      <ActionButton onAction={handleAction} actionButtonProps={actionButtonProps} isDestructive={isDestructive} />
    </View>
  ), [handleAction])

  const renderOption = React.useCallback((_props, i) => {
    const _onPress = () => {
      if (TypeGuards.isFunction(_props?.onPress)) _props?.onPress?.()
      if (autoClose) modalProps?.toggle?.(false)
    }

    return (
      <Button 
        {..._props} 
        variants={[_props?.style, ...(_props?.variants || [])]}
        onPress={_onPress}
        key={_props?.text + i} 
        style={variantStyles.option} 
      />
    )
  }, [options])

  return (
    <Modal
      showClose={false}
      closable={false}
      {...modalProps}
      styles={modalStyles}
      visible={modalProps?.visible && appStatus !== 'done' && appStatus !== 'loading'}
      // @ts-ignore
      variants={['centered', type, ...variants]}
    >
      {body ? <Text text={body} css={variantStyles.body} /> : null}

      {children}

      <View
        variants={[orientation]}
        css={variantStyles.actions}
      >
        {(showActionButton && !isRow && !optionsOnly) ? <ActionComponent /> : null}

        {!!optionsOnly ? null : (
          <View variants={['flex']}>
            <DismissButton onDismiss={handleDismiss} dismissButtonProps={dismissButtonProps} />
          </View>
        )}

        {hasOptions && options?.map(renderOption)}

        {(showActionButton && isRow && !optionsOnly) ? <ActionComponent /> : null}
      </View>
    </Modal>
  )
}

function GlobalAlertComponent() {
  const { visible, toggle, props } = OSAlertStore(store => store)

  const { title, body, onDismiss, onAction, options, type: customType, ...alertProps } = props

  const typeCast: Record<Exclude<GlobalAlertType, 'custom'>, AlertType> = {
    ask: 'destructive',
    info: 'info',
    warn: 'warn',
    error: 'error',
  }

  const type = typeCast[customType]

  const alertOptions = options?.map(o => ({ debugName: o?.text, ...o }))

  return <Alert
    debugName='Global alert'
    title={title}
    toggle={toggle}
    visible={visible}
    body={body}
    onAction={onAction}
    onDismiss={onDismiss}
    options={alertOptions}
    optionsOnly={!onAction && !onDismiss && !!options?.length}
    type={type || customType}
    {...alertProps}
  />
}

export const GlobalAlert = React.memo(GlobalAlertComponent, () => true)
