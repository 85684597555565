export const IS_SSR = typeof window === 'undefined'

export const DefaultDateFormat = 'dd/MM/yyyy'

export const MOBILE_BREAKPOINT = 'mobile'
export const MID_BREAKPOINT = 'tabletSmall'
export const LARGE_BREAKPOINT = 'desktop'

export const StyleConstants = {
  orangeGradient: {
    background: 'linear-gradient(270deg, #F88555 0%, #EF5533 100%)',
  },
  orangeGradientInverted: {
    background: 'linear-gradient(270deg, #EF5533 0%, #F88555 100%)',
  },
}
