import { I18N } from '../i18n'
import { setLocale } from 'yup'

/**Set Form default values */
setLocale({
  mixed: {
    required: () => I18N.t('form.validations.required'),
  },
  string: {
    url: () => I18N.t('form.validations.url'),
    email: () => I18N.t('form.validations.email'),
    min: ({ min }) => I18N.t('form.validations.min', { min }),
    max: ({ max }) => I18N.t('form.validations.max', { max }),
    length: ({ length }) => I18N.t('form.validations.max', { max: length }),
  },
})

export * from './posts'
export * from './auth'
export * from './testers'
export * from './contact'
