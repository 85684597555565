export const term = {
  en: {
    title: 'Terms',
    content: `Dooit App Terms and Conditions
Version 1.0 – 08 August 2024


1	Scope

These terms and conditions ("Terms") apply to the access to, and use of an online platform for connecting various service providers (each a "Service Provider") and clients (each a "Customer") (together the "Users") looking for the services offered by the Service Providers and related services (together the "Dooit Services"), available on Apple Store and Google Play for devices that utilizes Apple iOS or Android operating systems (the "App"), offered by Dooit App Sàrl, A-One Business Center ZA La Pièce 1 -A5 1180 Rolle, Switzerland ("Dooit").

To access or use the Dooit Services, you have to agree to these Terms. The Users agree to these Terms by creating a user account (the "User Account"). If the Users do not agree to these Terms, the Users may not use or access the Dooit Services.

These Terms, together with all other referenced documents, form a legally binding agreement ("Agreement") between the Users and Dooit.

The Users represents and warrants that they are not a person or entity barred from using the Dooit Services under the laws of Switzerland, their place of residence, or any other applicable jurisdiction. 


2	Dooit Services

2.1	Dooit App 

The App serves as an intermediary between the Users. Dooit is the owner and operator of the App and the contractual partner to the Users with regard to the Dooit Services. The App enables: 

•	The Customer to post detailed work requests for services ("Work Requests") based on their specific services needs (the "Service Provider Services") including pictures (if needed), and details on the date(s), emergency, location, timeline, provision of tools, and a brief description on the scope of the Service Provider Services; 
•	The Service Provider to browse Work Requests and offer their services ("Work Offers"), including providing quotes, timelines, and other relevant details. The Service Provider may also send a direct message about the Work Request; 
•	The Customer to access and read the information, rates and reviews related to the Service Provider available on their account and to accept or refuse the Work Offers related to a specific Work Request; 
•	The Users to interact with each other via the internal chat feature (the "Chat"); 
•	The Users to enter into a contractual agreement for the provision of the Service Provider Services upon acceptance of the respective Work Offer (each a “Service Agreement“); 
•	The Customer to delete the Work Request once the work is completed; 
•	The Users to rate and review each other's after the completion of the Work Request. 

2.2	Registration and Access to the Dooit Services

The Users must register an account to access and use all or part of the Dooit Services.

The Users must provide accurate, current, and complete information during registration and keep their User Account information up-to-date. User Accounts registered by bots or automated methods are not authorized and will be terminated. 

Upon registration, Service Providers shall provide the cancellation period during which the Services Agreement can be terminated ("Cancellation Term"). The Cancellation Term will be visible for the Customers in relation to a relevant Work Offer. Service Providers may amend their Cancellation Term within the limits made available on the App. In case of amendments, the amended Cancellation Term will be valid for future Work Offers only. 

The Users are responsible for maintaining the confidentiality and security of their account credentials and may not disclose their credentials to any third party. The Users are responsible and liable for activities conducted through their User Account and must immediately notify Dooit if there is any suspicion that their credentials have been lost, stolen, or their User Account is otherwise compromised.

2.3	Restrictions 

The App is intended to facilitate the connection between the Users for the provision of services as independent. Any other use that may be damaging to Dooit, or the Users is prohibited. In particular, but not limited to, the Users shall not use the App to hire employees or otherwise offer employment relationships. In case any use of the App has taken place in violation of these Terms or applicable law, Dooit reserves the right to suspend or terminate the access to the App.  

The App includes the Chat feature to facilitate communication between the Users. All communications via the Chat must be professional and relevant to the relevant Work Request. The Users shall in particular, but not exclusively, use the Chat for the provision of any illegal activity or to the deficit of Dooit. Dooit may monitor communications via Chat to ensure compliance with these Terms and to prevent misuse of the feature.


3	Rights & Obligations of Dooit

Dooit will provide the Users with the Dooit Services as agreed in the Agreement.

Dooit:

•	makes the Dooit Services available to the Users and uses reasonable care and skill in the performance of the Dooit Services and in keeping the Dooit Services free from viruses and other malicious software programs.
•	is permitted and possibly required by law to suspend access to the Dooit Services or the User account based upon reasonable determination of the occurrence or potential for occurrence of illegal or wrongful activity, fraudulent use or attempted fraudulent activity. In case of a suspension, the Users remains liable for all charges and fees incurred during the suspension period.

Dooit constantly develops and improves its Dooit Services and may modify or either temporarily or permanently stop providing the offered Dooit Services or any part of it at its sole discretion. In case of material changes to the Dooit Services, i.e., changes significantly altering the nature and scope of the Dooit Services provided to the Customer according to the Agreement, Dooit will notify the Users that are directly affected by such changes, and where reasonably possible.

Dooit reserves a right to ask the Users to provide feedback through forms, questionnaires, and polls in order to improve the Dooit Services ("Feedback"). Dooit may use, or not use, any such Feedback, without any obligation, whether financial or otherwise, to the Users. The Users assigns all rights (including but not limited to intellectual property rights), title, and interest in the Feedback to Dooit and acknowledge they have no claim in relation to the Feedback.


4	Service Agreement 

By accepting these Terms, Customers and Service Providers acknowledge that they understand and agree to the Services Agreement outlined in this section 4, including the basic level of services and the contractual obligations. The use of the App to enter into a Service Agreement constitutes acceptance of these Terms. 

The Users understands and acknowledges that (i) the Service Provider Services are not provided by Dooit, ,(ii) that the Service Provider Services are provided by the respective Service Provider to the respective Customer in accordance with the Service Agreement, and (iii) that the Service Provider is independent and that nothing in the Services Agreement will be construed to constitute Service Provider as an employee of the Customer.   

Service Providers agree to provide the Service Provider Services as detailed in the Customer’s Work Request. This includes all tasks specified in the request, performed in a professional, timely, and workmanlike manner, adhering to industry standards and best practices. The provision of the Service Provider Services must comply with all applicable laws, regulations, and safety standards.

Customers will post Work Requests through the App, detailing the scope of work, required materials, and any other relevant information. Service Providers may review these Work Requests and, if interested, submit a Work Offer for their services by providing a detailed quote, including cost, estimated time for completion, and any other terms specific to the Work Request. The Service Agreement is formed when the Customer accepts a Service Provider's Work Offer through the App, binding both parties to the agreed-upon terms. 

Upon conclusion of the Services Agreement, the relevant Customer and Service Provider may use the Chat to discuss directly via the App and the address of the Customer will be shared to the Service Provider. 

The Chat on the App can be used for all communications related to the Work Request. This includes discussing details, and coordinating schedules. Users must communicate promptly and professionally to ensure the smooth execution of the Service Provider Services.

Payment for Service Provider Services will be as specified in the accepted Work Offer. The terms of payment, including any deposits, and final payments, must be clearly outlined in the Work Offer accepted by the Customer or in any separate agreement between the relevant Customer and Service Provider. Invoicing and related documentation are the sole responsibility of the Service Provider and are not managed or otherwise provided by Dooit. Any disputes regarding payment must be resolved directly between the Customer and the Service Provider, although the Dooit may offer assistance in tracking and documenting payment agreements in the App.

Any modifications to the agreed-upon Service Provider Services after the acceptation of the Work Offer constituting of the Services Agreement must be documented in writing via the App’s internal chat feature and mutually agreed upon by both parties. 

Upon completion of the provision of the Service Provider Services, the Service Provider shall mark the Service Provider Services as completed on the App and may rate the Customer. The Customer may then rate the Service Provider. 

Either the Customer or the Service Provider may terminate the Services Agreement in accordance with the applicable Cancellation Term. Any termination outside of the respective Cancellation term must be agreed upon by the Customer and the Service Provider.

Service Providers warrant that all Service Provider Services provided will be free from defects and performed in a professional manner. Any issues arising from the provided Service Provider Services must be addressed by the Service Provider promptly and at no additional cost to the Customer. Service Providers are liable for any damages or losses resulting from their failure to perform the agreed-upon Service Provider Servicesto the specified standards.

In the event of a dispute, both the Customer and the Service Provider agree to communicate and attempt to resolve the issue in good faith using the App’s Chat or any other agreed upon communication method.

The Customer and the Service provider may at any point precise or otherwise amend the Services Agreement in a separate agreement agreed upon them.


5	Term & Termination

The Users and Dooit may terminate these Terms at any time with immediate effect. 

Termination does not affect any rights, obligations, or liabilities of either party that have accrued before or are intended to stay effective beyond termination. The termination of these Terms does not impact any Services Agreement concluded prior to termination. 


6	Intellectual Property

Each party retains all rights, titles, and interests to its own intellectual property, including all copyrights, inventions, trademarks, designs, domain names, know-how, trade secrets, data and other intangible property rights ("Intellectual Property Rights"). All Intellectual Property Rights in the Dooit Services or any part of it remain vested in Dooit.

Users agree to not decompile or reverse engineer the App or any part of it, or derive the source code, nor copy, modify, distribute, reproduce, translate, disassemble or use in any other way any information, text, graphics, images, software obtained from the use of the App, or any other part of the App.


7	Confidentiality & Privacy

Dooit treats the confidential information of the Users with adequate confidentiality standards and processes the Customer’s personal information only as needed. 

Dooit collects and processes personal data as described in its Privacy Policy available at https://dooitapp.ch/privacy_policy/?language=en. Dooit protects the collected personal data by means of appropriate technical and organizational measures and in accordance with the data protection legislation applicable in Switzerland and the European Union. 

The Users authorize Dooit to use, process, and store relevant data for the performance of the Agreement and to use anonymized data to improve its services or for analysis purposes.


8	Liability & Indemnity

Dooit is fully liable to the Users for damages resulting only from the Dooit's gross negligence or wilful misconduct.

In all other cases, Dooit's liability under the Agreement is excluded to the maximum extent permitted under applicable law.

Neither party may recover from the other party, regardless of the legal reason, any amount with respect to loss of profit, data, or goodwill, or any consequential, incidental, indirect, punitive, or special damages in connection with claims arising out of this Agreement or otherwise relating to the Dooit Services, whether or not the likelihood of such loss or damage was contemplated.

Dooit will not be held liable for inaccuracy or incompleteness of the Dooit Services, or the incompatibility of the Dooit Services with any specific objectives that the User is hoping to achieve. 


9	Warranties & Representations

Users acknowledge that the App is provided "as is" and "as available", and Dooit makes no warranties or representations of any kind related to the App or the information and materials contained thereon. 

Dooit does not guarantee that the App is error-free and will function without any interruption or disruption. Dooit may, at its own discretion, carry out maintenance or improvements to the App and its infrastructure, and Users acknowledge that this may result in temporary delays and interruptions from time to time. Where reasonably possible, Dooit will inform Users about potential interruptions in advance. Any further warranty is excluded.


10	Push Notifications & Newsletter

The Users may agree to receive push notifications, which may include important updates, offers, and relevant information from Dooit. Users can manage notification preferences in the App settings.  

By signing up for the Dooit's newsletter, the Users agree that Dooit may contact them and inform them about updates on the Dooit Services and new products from time to time. The Users can at any time unsubscribe from the contact list by sending an email to info@dooitapp.ch.


11	Miscellaneous

Entire Agreement: The Agreement constitutes the entire agreement between Dooit and the Users, and supersedes all prior agreements, between the parties relating to the subject matter of the Agreement.

Changes to Terms: Dooit may, from time to time, change these Terms. Users should check the Terms regularly and only use the Dooit Services upon acceptance of the changes to the Terms. The User's continued use of the App following any amendments indicates acceptance of the changes to the Terms.

Notices: Notices must be given in writing, including e-mail, and need to be communicated:

•	To Dooit's attention: via email to: info@dooitapp.ch;
•	To User's attention: by publishing on the App, or where explicitly agreed between the parties via email to the last e-mail address provided for this purpose by the User. It is the User's responsibility to keep provided contact information current.

No Assignment: The User may not assign any of its rights, obligations, or claims under the Agreement without the previous consent of Dooit. 

Severability: If any provision of the Agreement (in whole or part) is held to be illegal, invalid or otherwise unenforceable, the other provisions will remain in full force and effect. 

Governing Law & Jurisdiction: These Terms, and all claims or causes of action that may be based upon, arise out of or relate to these Terms shall be governed by and construed in accordance with the substantive laws of Switzerland, excluding its conflict of law provisions. The ordinary court at the seat of Dooit has exclusive jurisdiction for all disputes arising from or in connection with the Terms.

Links: The Dooit Services may contain third-party content or links to third-party websites. Dooit does not assume any responsibility for and does not make any warranties or representations as to any third-party content or websites, including but not limited to the accuracy, subject matter, quality, or timeliness.`,
  },
  fr: {
    title: 'Conditions',
    content: `Conditions d'utilisation de l'application Dooit
Version 1.0 - 08 August 2024


1	Champ d'application

Les présentes conditions générales (" Conditions ") s'appliquent à l'accès et à l'utilisation d'une plateforme en ligne de mise en relation de divers prestataires de services (chacun un " Prestataire de services ") et de clients (chacun un " Client ") (ensemble les " Utilisateurs ") à la recherche de services offerts par les Prestataires de services et de services connexes (ensemble les " Services Dooit "), disponible sur Apple Store et Google Play pour les appareils utilisant les systèmes d'exploitation Apple iOS ou Android (l'"Application"), proposée par Dooit App Sàrl, A-One Business Center ZA La Pièce 1 -A5 1180 Rolle, Suisse ("Dooit").

Pour accéder ou utiliser les services Dooit, vous devez accepter les présentes conditions. Les utilisateurs acceptent ces conditions en créant un compte utilisateur (le "compte utilisateur"). Si les utilisateurs n'acceptent pas ces conditions, ils ne peuvent pas utiliser ou accéder aux services Dooit.

Les présentes conditions, ainsi que tous les autres documents référencés, constituent un accord juridiquement contraignant ("Accord") entre les utilisateurs et Dooit.

L'utilisateur déclare et garantit qu'il n'est pas une personne ou une entité interdite d'utiliser les services Dooit en vertu des lois de la Suisse, de son lieu de résidence ou de toute autre juridiction applicable. 


2	Services Dooit

2.1	Application Dooit 

L'Application sert d'intermédiaire entre les Utilisateurs. Dooit est le propriétaire et l'opérateur de l'Application et le partenaire contractuel des Utilisateurs en ce qui concerne les Services Dooit. L'Application permet : 

•	Le client peut publier des demandes de services détaillées ("demandes de travaux") en fonction de ses besoins spécifiques (les "services du prestataire de services"), y compris des photos (si nécessaire) et des détails sur la ou les dates, l'urgence, le lieu, le calendrier, la fourniture d'outils et une brève description de la portée des services du prestataire de services ; 
•	Le prestataire de services peut parcourir les demandes de travaux et proposer ses services ("offres de travaux"), notamment en fournissant des devis, des délais et d'autres détails pertinents. Le prestataire de services peut également envoyer un message direct concernant la demande de travail ; 
•	Le client peut accéder et lire les informations, les tarifs et les avis relatifs au prestataire de services disponibles sur son compte et accepter ou refuser les offres de travail liées à une demande de travail spécifique ; 
•	Les utilisateurs peuvent interagir les uns avec les autres par le biais de la fonction de chat interne (le "chat") ; 
•	Les utilisateurs concluent un accord contractuel pour la fourniture des services du prestataire de services après avoir accepté l'offre de travail correspondante (chacun étant appelé "accord de service") ; 
•	Le client peut supprimer la demande de travaux une fois les travaux terminés ; 
•	Les utilisateurs peuvent évaluer et passer en revue les travaux des autres après la réalisation de la demande de travail. 

2.2	Inscription et accès aux services Dooit

Les utilisateurs doivent créer un compte pour accéder et utiliser tout ou partie des services Dooit.

Les utilisateurs doivent fournir des informations exactes, actuelles et complètes lors de l'inscription et tenir à jour les informations relatives à leur compte d'utilisateur. Les comptes d'utilisateurs enregistrés par des robots ou des méthodes automatisées ne sont pas autorisés et seront résiliés. 

Lors de l'enregistrement, les prestataires de services indiquent la période d'annulation au cours de laquelle l'accord de services peut être résilié ("délai d'annulation"). Le délai d'annulation sera visible pour les Clients en relation avec une offre de travail pertinente. Les Prestataires de services peuvent modifier leur délai d'annulation dans les limites mises à disposition sur l'App. En cas de modification, le délai d'annulation modifié ne sera valable que pour les offres de travail futures. 

Les utilisateurs sont responsables du maintien de la confidentialité et de la sécurité des informations d'identification de leur compte et ne peuvent pas divulguer ces informations à un tiers. Les utilisateurs sont responsables des activités menées par l'intermédiaire de leur compte utilisateur et doivent immédiatement avertir Dooit s'ils soupçonnent que leurs identifiants ont été perdus, volés ou que leur compte utilisateur est autrement compromis.

2.3	Restrictions 

L'App est destinée à faciliter la mise en relation des Utilisateurs pour la fourniture de services en tant qu'indépendants. Toute autre utilisation qui pourrait être préjudiciable à Dooit ou aux Utilisateurs est interdite. En particulier, mais sans s'y limiter, les Utilisateurs ne doivent pas utiliser l'Application pour embaucher des employés ou proposer des relations de travail. En cas d'utilisation de l'application en violation de ces conditions ou de la loi applicable, Dooit se réserve le droit de suspendre ou de résilier l'accès à l'application.  

L'Application inclut la fonction Chat pour faciliter la communication entre les Utilisateurs. Toutes les communications via le Chat doivent être professionnelles et pertinentes par rapport à la demande de travail concernée. Les Utilisateurs doivent notamment, mais pas exclusivement, utiliser le Chat pour la fourniture de toute activité illégale ou au déficit de Dooit. Dooit peut surveiller les communications via le Chat pour s'assurer de la conformité avec les présentes Conditions et pour empêcher toute utilisation abusive de la fonctionnalité.


3	Droits et obligations de Dooit

Dooit fournira aux utilisateurs les services Dooit comme convenu dans la convention.

Doit :

•	met les services Dooit à la disposition des utilisateurs et fait preuve d'un soin et d'une compétence raisonnables dans l'exécution des services Dooit et dans le maintien des services Dooit exempts de virus et d'autres programmes logiciels malveillants.
•	est autorisé et éventuellement tenu par la loi de suspendre l'accès aux services Dooit ou au compte de l'utilisateur sur la base d'une détermination raisonnable de l'occurrence ou du potentiel d'occurrence d'une activité illégale ou répréhensible, d'une utilisation frauduleuse ou d'une tentative d'activité frauduleuse. En cas de suspension, l'utilisateur reste responsable de tous les frais encourus pendant la période de suspension.

Dooit développe et améliore constamment ses services Dooit et peut modifier ou cesser temporairement ou définitivement de fournir les services Dooit offerts ou toute partie de ceux-ci à sa seule discrétion. En cas de changements matériels des Services Dooit, c'est-à-dire de changements modifiant de manière significative la nature et la portée des Services Dooit fournis au Client conformément au Contrat, Dooit notifiera les Utilisateurs qui sont directement affectés par de tels changements, et dans la mesure du possible.

Dooit se réserve le droit de demander aux utilisateurs de fournir un retour d'information par le biais de formulaires, de questionnaires et de sondages afin d'améliorer les services Dooit ("retour d'information"). Dooit peut utiliser, ou ne pas utiliser, ces commentaires, sans aucune obligation, financière ou autre, envers les utilisateurs. L'utilisateur cède à Dooit tous les droits (y compris, mais sans s'y limiter, les droits de propriété intellectuelle), le titre et l'intérêt dans le retour d'information et reconnaît qu'il n'a aucune réclamation en relation avec le retour d'information.


4	Accord de service 

En acceptant les présentes conditions, les clients et les prestataires de services reconnaissent qu'ils comprennent et acceptent l'accord de services décrit dans la présente section. 4y compris le niveau de base des services et les obligations contractuelles. L'utilisation de l'application pour conclure un accord de service constitue l'acceptation des présentes conditions. 

Les utilisateurs comprennent et reconnaissent que (i) les services du fournisseur de services ne sont pas fournis par Dooit, (ii) que les services du fournisseur de services sont fournis par le fournisseur de services respectif au client respectif conformément à l'accord de service, et (iii) que le fournisseur de services est indépendant et que rien dans l'accord de services ne sera interprété comme faisant du fournisseur de services un employé du client.   

Les prestataires de services s'engagent à fournir les services du prestataire de services tels qu'ils sont détaillés dans la demande de travail du client. Cela inclut toutes les tâches spécifiées dans la demande, exécutées de manière professionnelle, dans les délais et selon les règles de l'art, dans le respect des normes et des meilleures pratiques du secteur. La fourniture des services du prestataire de services doit être conforme à toutes les lois, réglementations et normes de sécurité applicables.

Les clients publieront des demandes de travaux par l'intermédiaire de l'application, en détaillant l'étendue des travaux, les matériaux requis et toute autre information pertinente. Les Prestataires de services peuvent examiner ces demandes de travaux et, s'ils sont intéressés, soumettre une offre de travaux pour leurs services en fournissant un devis détaillé, y compris le coût, le temps estimé pour l'achèvement, et toute autre condition spécifique à la demande de travaux. L'accord de service est conclu lorsque le client accepte l'offre de travail d'un prestataire de services via l'application, ce qui lie les deux parties aux conditions convenues. 

À la conclusion de l'accord de services, le client et le prestataire de services concernés peuvent utiliser le chat pour discuter directement via l'application et l'adresse du client sera communiquée au prestataire de services. 

Le chat de l'application peut être utilisé pour toutes les communications liées à la demande de travaux. Cela inclut la discussion des détails et la coordination des horaires. Les utilisateurs doivent communiquer rapidement et de manière professionnelle afin d'assurer la bonne exécution des services du prestataire de services.

Le paiement des services du prestataire de services se fera selon les modalités spécifiées dans l'offre de travail acceptée. Les conditions de paiement, y compris les acomptes et les paiements finaux, doivent être clairement définies dans l'offre de travail acceptée par le client ou dans tout autre accord séparé entre le client et le prestataire de services. La facturation et la documentation y afférente relèvent de la seule responsabilité du Prestataire et ne sont pas gérées ou fournies par Dooit. Tout litige concernant le paiement doit être résolu directement entre le Client et le Prestataire, bien que Dooit puisse offrir une assistance dans le suivi et la documentation des accords de paiement dans l'Application.

Toute modification des services du prestataire de services convenus après l'acceptation de l'offre de travail constituant l'accord de services doit être documentée par écrit via la fonction de chat interne de l'application et faire l'objet d'un accord mutuel entre les deux parties. 

Une fois la fourniture des services du prestataire de services terminée, le prestataire de services indiquera sur l'application que les services du prestataire de services sont terminés et pourra noter le client. Le client peut ensuite évaluer le prestataire de services. 

Le client ou le prestataire de services peut résilier l'accord de services conformément au délai de résiliation applicable. Toute résiliation en dehors du délai de résiliation respectif doit faire l'objet d'un accord entre le client et le prestataire de services.

Les prestataires de services garantissent que tous les services fournis par le prestataire de services sont exempts de défauts et exécutés de manière professionnelle. Tout problème lié aux services fournis par le prestataire de services doit être traité par le prestataire de services dans les meilleurs délais et sans frais supplémentaires pour le client. Les prestataires de services sont responsables de tout dommage ou perte résultant de leur incapacité à fournir les services convenus selon les normes spécifiées.

En cas de litige, le Client et le Prestataire de service acceptent de communiquer et de tenter de résoudre le problème de bonne foi en utilisant le Chat de l'App ou toute autre méthode de communication convenue.

Le client et le prestataire de services peuvent à tout moment préciser ou modifier de toute autre manière le contrat de services dans un accord distinct convenu entre eux.

5	Durée et résiliation

Les utilisateurs et Dooit peuvent résilier les présentes conditions à tout moment avec effet immédiat. 

La résiliation n'affecte pas les droits, obligations ou responsabilités de l'une ou l'autre partie qui sont nés avant la résiliation ou qui sont destinés à rester en vigueur au-delà de la résiliation. La résiliation des présentes conditions n'a pas d'incidence sur les accords de services conclus avant la résiliation. 


6	Propriété intellectuelle

Chaque partie conserve tous les droits, titres et intérêts relatifs à sa propre propriété intellectuelle, y compris tous les droits d'auteur, inventions, marques, dessins, noms de domaine, savoir-faire, secrets commerciaux, données et autres droits de propriété incorporelle ("droits de propriété intellectuelle"). Tous les droits de propriété intellectuelle des services Dooit ou d'une partie de ceux-ci restent acquis à Dooit.

Les utilisateurs s'engagent à ne pas décompiler ou désosser l'application ou toute partie de celle-ci, ni à dériver le code source, ni à copier, modifier, distribuer, reproduire, traduire, désassembler ou utiliser de toute autre manière toute information, texte, graphique, image, logiciel obtenu à partir de l'utilisation de l'application ou de toute autre partie de l'application.


7	Confidentialité et vie privée

Dooit traite les informations confidentielles des utilisateurs avec des normes de confidentialité adéquates et traite les informations personnelles du client uniquement en cas de besoin. 

Dooit collecte et traite des données personnelles comme décrit dans sa Politique de confidentialité disponible sur https://dooitapp.ch/privacy_policy/?language=fr. Dooit protège les données personnelles collectées au moyen de mesures techniques et organisationnelles appropriées et conformément à la législation sur la protection des données applicable en Suisse et dans l'Union européenne. 

Les utilisateurs autorisent Dooit à utiliser, traiter et stocker les données pertinentes pour l'exécution du contrat et à utiliser des données anonymes pour améliorer ses services ou à des fins d'analyse.


8	Responsabilité et indemnisation

Dooit est entièrement responsable envers les utilisateurs des dommages résultant uniquement de la négligence grave ou de la faute intentionnelle de Dooit.

Dans tous les autres cas, la responsabilité de Dooit en vertu de la convention est exclue dans la mesure maximale permise par la loi applicable.

Aucune des parties ne peut récupérer auprès de l'autre partie, quel que soit le motif juridique, un quelconque montant relatif à la perte de profit, de données ou de fonds de commerce, ou tout autre dommage consécutif, accessoire, indirect, punitif ou spécial en rapport avec des réclamations découlant du présent accord ou autrement liées aux services Dooit, que la probabilité d'une telle perte ou d'un tel dommage ait été envisagée ou non.

Dooit ne sera pas responsable de l'inexactitude ou du caractère incomplet des Services Dooit, ni de l'incompatibilité des Services Dooit avec les objectifs spécifiques que l'Utilisateur souhaite atteindre. 


9	Garanties et déclarations

Les utilisateurs reconnaissent que l'application est fournie "en l'état" et "telle que disponible", et que Dooit n'offre aucune garantie ou représentation de quelque nature que ce soit concernant l'application ou les informations et matériaux qu'elle contient. 

Dooit ne garantit pas que l'application est exempte d'erreurs et fonctionnera sans interruption ou perturbation. Dooit peut, à sa propre discrétion, effectuer des travaux de maintenance ou d'amélioration de l'application et de son infrastructure, et les utilisateurs reconnaissent que cela peut entraîner des retards temporaires et des interruptions de temps en temps. Dans la mesure du possible, Dooit informera les utilisateurs des interruptions potentielles à l'avance. Toute autre garantie est exclue.


10	Notifications push et lettre d'information

Les utilisateurs peuvent accepter de recevoir des notifications push, qui peuvent inclure des mises à jour importantes, des offres et des informations pertinentes de Dooit. Les utilisateurs peuvent gérer les préférences de notification dans les paramètres de l'application.  

En s'inscrivant à la lettre d'information de Dooit, les utilisateurs acceptent que Dooit les contacte et les informe des mises à jour des services Dooit et des nouveaux produits de temps en temps. Les utilisateurs peuvent à tout moment se désinscrire de la liste de contact en envoyant un email à info@dooitapp.ch.


11	Divers

Intégralité de l'accord : Le Contrat constitue l'intégralité de l'accord entre Dooit et les Utilisateurs, et remplace tous les accords antérieurs entre les parties concernant l'objet du Contrat.

Modifications des conditions : Dooit peut, de temps à autre, modifier ces conditions. Les utilisateurs doivent vérifier les conditions régulièrement et n'utiliser les services Dooit qu'après avoir accepté les changements des conditions. La poursuite de l'utilisation de l'application par l'utilisateur après toute modification indique l'acceptation des modifications apportées aux conditions.

Avis : Les avis doivent être donnés par écrit, y compris par courrier électronique, et doivent être communiqués :

•	A l'attention de Dooit : par courriel à : info@dooitapp.ch ;
•	A l'attention de l'Utilisateur : par publication sur l'Application, ou en cas d'accord explicite entre les parties par courriel à la dernière adresse électronique fournie à cet effet par l'Utilisateur. Il est de la responsabilité de l'Utilisateur de maintenir à jour les informations de contact fournies.

Pas de cession : L'utilisateur ne peut céder aucun de ses droits, obligations ou réclamations dans le cadre du contrat sans l'accord préalable de Dooit. 

Divisibilité : Si une disposition de l'accord (en tout ou en partie) est jugée illégale, invalide ou inapplicable, les autres dispositions resteront pleinement en vigueur. 

Droit applicable et juridiction : Les présentes conditions, ainsi que toutes les réclamations ou causes d'action qui peuvent être fondées sur les présentes conditions, en découler ou s'y rapporter, sont régies et interprétées conformément au droit substantiel de la Suisse, à l'exclusion des dispositions relatives aux conflits de lois. Le tribunal ordinaire du siège de Dooit a la compétence exclusive pour tous les litiges découlant des conditions ou liés à celles-ci.

Liens : Les Services Dooit peuvent contenir du contenu de tiers ou des liens vers des sites web de tiers. Dooit n'assume aucune responsabilité et n'offre aucune garantie quant au contenu ou aux sites web de tiers, y compris, mais sans s'y limiter, l'exactitude, le sujet, la qualité ou l'actualité.`,
  },
  de: {
    title: 'Bedingungen',
    content: `Dooit App Bedingungen und Konditionen
Version 1.0 - 08 August 2024


1	Umfang

Diese Bedingungen ("Bedingungen") gelten für den Zugang zu und die Nutzung einer Online-Plattform für die Verbindung zwischen verschiedenen Dienstanbietern (jeweils ein "Dienstanbieter") und Kunden (jeweils ein "Kunde") (zusammen die "Nutzer"), die nach den von den Dienstanbietern angebotenen Dienstleistungen und damit verbundenen Dienstleistungen (zusammen die "Dooit-Dienste") suchen, die im Apple Store und bei Google Play für Geräte mit den Betriebssystemen Apple iOS oder Android verfügbar sind (die "App"), angeboten von Dooit App Sàrl, A-One Business Center ZA La Pièce 1 -A5 1180 Rolle, Schweiz ("Dooit").

Um auf die Dooit-Dienste zuzugreifen oder diese zu nutzen, müssen Sie diesen Bedingungen zustimmen. Die Benutzer erklären sich mit diesen Bedingungen einverstanden, indem sie ein Benutzerkonto (das "Benutzerkonto") erstellen. Wenn die Nutzer diesen Bedingungen nicht zustimmen, dürfen sie die Dooit-Dienste nicht nutzen oder darauf zugreifen.

Diese Bedingungen bilden zusammen mit allen anderen referenzierten Dokumenten eine rechtsverbindliche Vereinbarung ("Vereinbarung") zwischen den Nutzern und Dooit.

Der Nutzer sichert zu und gewährleistet, dass er keine natürliche oder juristische Person ist, der die Nutzung der Dooit-Dienste nach den Gesetzen der Schweiz, seines Wohnsitzes oder einer anderen anwendbaren Rechtsordnung untersagt ist. 


2	Dooit Dienstleistungen

2.1	Dooit App 

Die App dient als Vermittler zwischen den Nutzern. Dooit ist der Eigentümer und Betreiber der App und der Vertragspartner der Nutzer in Bezug auf die Dooit-Dienste. Die App ermöglicht: 

•	Der Kunde kann detaillierte Arbeitsanfragen für Dienstleistungen ("Arbeitsanfragen") auf der Grundlage seiner spezifischen Dienstleistungsbedürfnisse (die "Dienstleistungen des Dienstleisters") veröffentlichen, einschließlich Bildern (falls erforderlich) und Details zu Datum/Daten, Notfall, Ort, Zeitplan, Bereitstellung von Werkzeugen und einer kurzen Beschreibung des Umfangs der Dienstleistungen des Dienstleisters; 
•	Der Dienstleister kann Arbeitsanfragen durchsuchen und seine Dienste anbieten ("Arbeitsangebote"), einschließlich der Angabe von Kostenvoranschlägen, Zeitplänen und anderen relevanten Details. Der Dienstleister kann auch eine direkte Nachricht über die Arbeitsanfrage senden; 
•	Der Kunde hat die Möglichkeit, die Informationen, Tarife und Bewertungen des Dienstleisters, die in seinem Konto verfügbar sind, einzusehen und zu lesen und die Arbeitsangebote, die sich auf eine bestimmte Arbeitsanfrage beziehen, anzunehmen oder abzulehnen; 
•	Die Nutzer können über die interne Chat-Funktion (der "Chat") miteinander kommunizieren; 
•	die Nutzer, mit der Annahme des jeweiligen Arbeitsangebots eine vertragliche Vereinbarung über die Erbringung der Dienstleistungen des Dienstanbieters abzuschließen (jeweils ein "Dienstvertrag"); 
•	Der Kunde kann den Arbeitsauftrag löschen, sobald die Arbeit abgeschlossen ist; 
•	Die Nutzer können sich nach Abschluss des Arbeitsauftrags gegenseitig bewerten und überprüfen. 

2.2	Registrierung und Zugang zu den Dooit-Diensten

Die Nutzer müssen ein Konto registrieren, um auf alle oder einen Teil der Dooit-Dienste zugreifen und diese nutzen zu können.

Die Nutzer sind verpflichtet, bei der Registrierung genaue, aktuelle und vollständige Angaben zu machen und ihre Nutzerkontoinformationen auf dem neuesten Stand zu halten. Benutzerkonten, die durch Bots oder automatisierte Methoden registriert wurden, sind nicht zulässig und werden gelöscht. 

Bei der Registrierung geben die Dienstleister die Kündigungsfrist an, innerhalb derer der Dienstleistungsvertrag gekündigt werden kann ("Kündigungsfrist"). Die Kündigungsfrist wird für die Kunden in Bezug auf ein bestimmtes Arbeitsangebot sichtbar sein. Die Diensteanbieter können ihre Kündigungsfrist innerhalb der in der App zur Verfügung gestellten Grenzen ändern. Im Falle von Änderungen gilt die geänderte Kündigungsfrist nur für zukünftige Arbeitsangebote. 

Die Nutzer sind für die Wahrung der Vertraulichkeit und Sicherheit ihrer Anmeldedaten verantwortlich und dürfen ihre Anmeldedaten nicht an Dritte weitergeben. Die Nutzer sind verantwortlich und haftbar für Aktivitäten, die über ihr Nutzerkonto durchgeführt werden, und müssen Dooit unverzüglich benachrichtigen, wenn der Verdacht besteht, dass ihre Anmeldedaten verloren gegangen sind, gestohlen wurden oder ihr Nutzerkonto auf andere Weise gefährdet ist.

2.3	Beschränkungen 

Die App ist dazu bestimmt, die Verbindung zwischen den Nutzern für die Erbringung von Dienstleistungen als unabhängig zu erleichtern. Jede andere Nutzung, die Dooit oder den Nutzern schaden könnte, ist verboten. Insbesondere, aber nicht ausschließlich, dürfen die Nutzer die App nicht nutzen, um Mitarbeiter einzustellen oder anderweitig Arbeitsverhältnisse anzubieten. Im Falle einer Nutzung der App, die gegen diese Bedingungen oder geltendes Recht verstößt, behält sich Dooit das Recht vor, den Zugang zur App auszusetzen oder zu beenden.  

Die App enthält die Chat-Funktion, um die Kommunikation zwischen den Nutzern zu erleichtern. Die gesamte Kommunikation über den Chat muss professionell und relevant für den jeweiligen Arbeitsauftrag sein. Die Nutzer dürfen den Chat insbesondere, aber nicht ausschließlich, für die Durchführung illegaler Aktivitäten oder zum Nachteil von Dooit nutzen. Dooit kann die Kommunikation über den Chat überwachen, um die Einhaltung dieser Bedingungen sicherzustellen und einen Missbrauch der Funktion zu verhindern.

3	Rechte und Pflichten von Dooit

Dooit stellt den Nutzern die Dooit-Dienste wie in der Vereinbarung vereinbart zur Verfügung.

Dooit:

•	den Nutzern die Dooit-Dienste zur Verfügung stellt und bei der Erbringung der Dooit-Dienste mit angemessener Sorgfalt und Sachkenntnis vorgeht und die Dooit-Dienste frei von Viren und anderen schädlichen Softwareprogrammen hält.
•	ist berechtigt und möglicherweise gesetzlich verpflichtet, den Zugang zu den Dooit-Diensten oder dem Benutzerkonto zu sperren, wenn das Auftreten oder mögliche Auftreten von illegalen oder unrechtmäßigen Aktivitäten, betrügerischer Nutzung oder versuchten betrügerischen Aktivitäten festgestellt wird. Im Falle einer Sperrung haftet der Nutzer für alle während des Sperrzeitraums angefallenen Gebühren und Entgelte.

Dooit entwickelt und verbessert seine Dooit-Dienste ständig und kann die angebotenen Dooit-Dienste oder Teile davon nach eigenem Ermessen ändern oder vorübergehend oder dauerhaft einstellen. Im Falle wesentlicher Änderungen der Dooit-Dienste, d.h. Änderungen, die Art und Umfang der dem Kunden gemäß der Vereinbarung bereitgestellten Dooit-Dienste erheblich verändern, wird Dooit die von diesen Änderungen unmittelbar betroffenen Nutzer, soweit dies möglich ist, informieren.

Dooit behält sich das Recht vor, die Nutzer aufzufordern, über Formulare, Fragebögen und Umfragen Feedback zu geben, um die Dooit-Dienste zu verbessern ("Feedback"). Dooit kann dieses Feedback verwenden oder nicht verwenden, ohne dass den Nutzern gegenüber eine finanzielle oder sonstige Verpflichtung entsteht. Die Nutzer treten alle Rechte (einschließlich, aber nicht beschränkt auf geistige Eigentumsrechte), Titel und Interessen an dem Feedback an Dooit ab und erkennen an, dass sie keine Ansprüche in Bezug auf das Feedback haben.


4	Dienstleistungsvertrag 

Durch die Annahme dieser Bedingungen bestätigen Kunden und Dienstleister, dass sie die in diesem Abschnitt dargelegte Dienstleistungsvereinbarung verstehen und ihr zustimmen. 4einschließlich des grundlegenden Leistungsumfangs und der vertraglichen Verpflichtungen. Die Nutzung der App zum Abschluss einer Dienstleistungsvereinbarung stellt eine Annahme dieser Bedingungen dar. 

Die Nutzer verstehen und erkennen an, dass (i) die Dienste des Dienstanbieters nicht von Dooit bereitgestellt werden, (ii) dass die Dienste des Dienstanbieters vom jeweiligen Dienstanbieter für den jeweiligen Kunden in Übereinstimmung mit der Dienstvereinbarung bereitgestellt werden und (iii) dass der Dienstanbieter unabhängig ist und dass nichts in der Dienstvereinbarung so ausgelegt wird, dass der Dienstanbieter ein Angestellter des Kunden ist.   

Die Dienstanbieter verpflichten sich, die Dienstleistungen des Dienstanbieters so zu erbringen, wie sie in der Arbeitsanfrage des Kunden aufgeführt sind. Dazu gehören alle in der Anfrage genannten Aufgaben, die professionell, pünktlich und fachmännisch unter Einhaltung von Industriestandards und bewährten Verfahren ausgeführt werden. Bei der Erbringung der Dienstleistungen des Dienstleisters müssen alle geltenden Gesetze, Vorschriften und Sicherheitsstandards eingehalten werden.

Kunden stellen über die App Arbeitsanfragen, in denen sie den Umfang der Arbeit, die benötigten Materialien und alle anderen relevanten Informationen angeben. Dienstleister können diese Arbeitsanfragen prüfen und bei Interesse ein Angebot für ihre Dienstleistungen unterbreiten, indem sie ein detailliertes Angebot, einschließlich der Kosten, der geschätzten Zeit für die Fertigstellung und anderer spezifischer Bedingungen für die Arbeitsanfrage, einreichen. Der Dienstleistungsvertrag kommt zustande, wenn der Kunde das Arbeitsangebot eines Dienstanbieters über die App annimmt und beide Parteien an die vereinbarten Bedingungen bindet. 

Nach Abschluss des Dienstleistungsvertrags können der betreffende Kunde und der Dienstleister den Chat nutzen, um direkt über die App zu diskutieren, und die Adresse des Kunden wird dem Dienstleister mitgeteilt. 

Der Chat in der App kann für die gesamte Kommunikation im Zusammenhang mit dem Arbeitsauftrag genutzt werden. Dazu gehören die Besprechung von Details und die Koordinierung von Zeitplänen. Die Nutzer müssen zeitnah und professionell kommunizieren, um eine reibungslose Ausführung der Dienstleistungen des Dienstleisters zu gewährleisten.

Die Zahlung für die Dienstleistungen des Dienstleisters erfolgt wie im angenommenen Arbeitsangebot angegeben. Die Zahlungsbedingungen, einschließlich etwaiger Anzahlungen und Abschlusszahlungen, müssen in dem vom Kunden akzeptierten Arbeitsangebot oder in einer separaten Vereinbarung zwischen dem jeweiligen Kunden und dem Dienstleister klar dargelegt werden. Die Rechnungsstellung und die dazugehörige Dokumentation liegen in der alleinigen Verantwortung des Dienstleisters und werden nicht von Dooit verwaltet oder anderweitig bereitgestellt. Etwaige Zahlungsstreitigkeiten müssen direkt zwischen dem Kunden und dem Dienstleister geklärt werden, obwohl Dooit Unterstützung bei der Verfolgung und Dokumentation von Zahlungsvereinbarungen in der App anbieten kann.

Jegliche Änderungen an den vereinbarten Dienstleistungen des Dienstleisters nach der Annahme des Arbeitsangebots, das Bestandteil des Dienstleistungsvertrags ist, müssen schriftlich über die interne Chat-Funktion der App dokumentiert und von beiden Parteien einvernehmlich vereinbart werden. 

Nach Abschluss der Erbringung der Dienstleistungen des Dienstanbieters markiert der Dienstanbieter die Dienstleistungen des Dienstanbieters in der App als abgeschlossen und kann den Kunden bewerten. Der Kunde kann dann den Dienstanbieter bewerten. 

Sowohl der Kunde als auch der Dienstanbieter können den Dienstleistungsvertrag gemäß der geltenden Kündigungsfrist kündigen. Jede Kündigung außerhalb der jeweiligen Kündigungsfrist muss zwischen dem Kunden und dem Dienstleister vereinbart werden.

Der Dienstanbieter garantiert, dass alle von ihm erbrachten Dienstleistungen frei von Mängeln sind und fachgerecht ausgeführt werden. Alle Probleme, die im Zusammenhang mit den erbrachten Dienstleistungen des Dienstleisters auftreten, müssen vom Dienstleister unverzüglich und ohne zusätzliche Kosten für den Kunden behoben werden. Der Dienstanbieter haftet für alle Schäden oder Verluste, die sich aus der Nichterfüllung der vereinbarten Dienstleistungen des Dienstanbieters gemäß den festgelegten Standards ergeben.

Im Falle einer Streitigkeit verpflichten sich sowohl der Kunde als auch der Dienstanbieter, miteinander zu kommunizieren und zu versuchen, das Problem in gutem Glauben zu lösen, indem sie den Chat der App oder eine andere vereinbarte Kommunikationsmethode nutzen.

Der Kunde und der Dienstanbieter können die Dienstleistungsvereinbarung jederzeit durch eine gesonderte Vereinbarung zwischen ihnen präzisieren oder anderweitig ändern.


5	Laufzeit & Beendigung

Die Nutzer und Dooit können diese Bedingungen jederzeit mit sofortiger Wirkung kündigen. 

Die Kündigung berührt keine Rechte, Pflichten oder Verbindlichkeiten einer der Parteien, die vor der Kündigung entstanden sind oder die über die Kündigung hinaus wirksam bleiben sollen. Die Beendigung dieser Bedingungen wirkt sich nicht auf einen vor der Beendigung abgeschlossenen Dienstleistungsvertrag aus. 


6	Geistiges Eigentum

Jede Partei behält alle Rechte, Titel und Interessen an ihrem eigenen geistigen Eigentum, einschließlich aller Urheberrechte, Erfindungen, Marken, Designs, Domainnamen, Know-how, Geschäftsgeheimnisse, Daten und anderer immaterieller Eigentumsrechte ("Rechte an geistigem Eigentum"). Alle Rechte an geistigem Eigentum an den Dooit-Diensten oder Teilen davon verbleiben bei Dooit.

Die Nutzer verpflichten sich, die App oder Teile davon nicht zu dekompilieren oder zurückzuentwickeln, den Quellcode nicht abzuleiten, Informationen, Texte, Grafiken, Bilder, Software, die durch die Nutzung der App oder anderer Teile der App erhalten wurden, nicht zu kopieren, zu verändern, zu verteilen, zu reproduzieren, zu übersetzen, zu disassemblieren oder in irgendeiner anderen Weise zu nutzen.


7	Vertraulichkeit und Datenschutz

Dooit behandelt die vertraulichen Informationen der Nutzer mit angemessenen Vertraulichkeitsstandards und verarbeitet die persönlichen Daten des Kunden nur nach Bedarf. 

Dooit sammelt und verarbeitet personenbezogene Daten, wie in der Datenschutzrichtlinie beschrieben, die unter https://dooitapp.ch/privacy_policy/?language=de verfügbar ist. Dooit schützt die erhobenen Personendaten durch geeignete technische und organisatorische Massnahmen und in Übereinstimmung mit den in der Schweiz und der Europäischen Union geltenden Datenschutzgesetzen. 

Die Nutzer ermächtigen Dooit, die relevanten Daten zur Erfüllung des Vertrages zu nutzen, zu verarbeiten und zu speichern sowie anonymisierte Daten zur Verbesserung der Dienste oder zu Analysezwecken zu verwenden.


8	Haftung und Entschädigung

Dooit haftet gegenüber den Nutzern in vollem Umfang für Schäden, die nur auf grobe Fahrlässigkeit oder Vorsatz von Dooit zurückzuführen sind.

In allen anderen Fällen ist die Haftung von Dooit im Rahmen der Vereinbarung im größtmöglichen Umfang ausgeschlossen, den das geltende Recht zulässt.

Keine der Parteien kann von der anderen Partei, gleich aus welchem Rechtsgrund, einen Betrag für entgangenen Gewinn, Daten oder Firmenwert oder für Folgeschäden, beiläufig entstandene Schäden, indirekte Schäden, Schäden mit Strafcharakter oder besondere Schäden in Verbindung mit Ansprüchen, die sich aus dieser Vereinbarung oder anderweitig im Zusammenhang mit den Dooit-Diensten ergeben, zurückfordern, unabhängig davon, ob die Wahrscheinlichkeit eines solchen Verlusts oder Schadens vorausgesehen wurde oder nicht.

Dooit haftet nicht für die Ungenauigkeit oder Unvollständigkeit der Dooit-Dienste oder für die Unvereinbarkeit der Dooit-Dienste mit den spezifischen Zielen, die der Nutzer zu erreichen hofft. 


9	Garantien und Zusicherungen

Die Nutzer erkennen an, dass die App "wie besehen" und "wie verfügbar" zur Verfügung gestellt wird, und dass Dooit keine Garantien oder Zusicherungen jeglicher Art in Bezug auf die App oder die darin enthaltenen Informationen und Materialien gibt. 

Dooit garantiert nicht, dass die App fehlerfrei ist und ohne Unterbrechung oder Störung funktionieren wird. Dooit kann nach eigenem Ermessen Wartungsarbeiten oder Verbesserungen an der App und ihrer Infrastruktur vornehmen, und die Nutzer erkennen an, dass dies von Zeit zu Zeit zu vorübergehenden Verzögerungen und Unterbrechungen führen kann. Soweit dies möglich ist, wird Dooit die Nutzer im Voraus über mögliche Unterbrechungen informieren. Jede weitere Gewährleistung ist ausgeschlossen.


10	Push-Benachrichtigungen & Newsletter

Die Nutzer können zustimmen, Push-Benachrichtigungen zu erhalten, die wichtige Updates, Angebote und relevante Informationen von Dooit enthalten können. Die Benutzer können die Benachrichtigungseinstellungen in den App-Einstellungen verwalten.  

Mit der Anmeldung zum Newsletter von Dooit erklären sich die Nutzer damit einverstanden, dass Dooit sie kontaktiert und sie von Zeit zu Zeit über Updates zu den Dooit-Diensten und neuen Produkten informiert. Die Nutzer können sich jederzeit aus der Kontaktliste austragen, indem sie eine E-Mail an info@dooitapp.ch senden.


11	Sonstiges

Gesamte Vereinbarung: Die Vereinbarung stellt die gesamte Vereinbarung zwischen Dooit und den Nutzern dar und ersetzt alle früheren Vereinbarungen zwischen den Parteien, die sich auf den Gegenstand der Vereinbarung beziehen.

Änderungen der Bedingungen: Dooit kann diese Bedingungen von Zeit zu Zeit ändern. Die Nutzer sollten die Bedingungen regelmäßig überprüfen und die Dooit-Dienste nur nutzen, wenn sie den Änderungen der Bedingungen zustimmen. Die fortgesetzte Nutzung der App durch den Nutzer nach einer Änderung bedeutet, dass er die Änderungen der Bedingungen akzeptiert.

Bekanntmachungen: Bekanntmachungen müssen schriftlich erfolgen, auch per E-Mail, und sie müssen mitgeteilt werden:

•	Zu Händen von Dooit: per E-Mail an: info@dooitapp.ch;
•	Zu Händen des Nutzers: durch Veröffentlichung in der App oder, wenn dies ausdrücklich zwischen den Parteien vereinbart wurde, per E-Mail an die letzte vom Nutzer zu diesem Zweck angegebene E-Mail-Adresse. Es liegt in der Verantwortung des Nutzers, die bereitgestellten Kontaktinformationen aktuell zu halten.

Keine Abtretung: Der Nutzer darf seine Rechte, Pflichten oder Ansprüche aus dieser Vereinbarung nicht ohne vorherige Zustimmung von Dooit abtreten. 

Trennbarkeit: Sollte eine Bestimmung der Vereinbarung (ganz oder teilweise) für rechtswidrig, ungültig oder anderweitig nicht durchsetzbar befunden werden, so bleiben die übrigen Bestimmungen in vollem Umfang in Kraft und wirksam. 

Geltendes Recht und Gerichtsstand: Diese Bedingungen und alle Ansprüche oder Klagegründe, die auf diesen Bedingungen beruhen, sich aus ihnen ergeben oder mit ihnen in Zusammenhang stehen, unterliegen dem materiellen Recht der Schweiz unter Ausschluss der Bestimmungen des Kollisionsrechts. Das ordentliche Gericht am Sitz von Dooit hat die ausschließliche Zuständigkeit für alle Streitigkeiten, die sich aus oder im Zusammenhang mit den Bedingungen ergeben.

Links: Die Dooit-Dienste können Inhalte Dritter oder Links zu Websites Dritter enthalten. Dooit übernimmt keine Verantwortung und gibt keine Garantien oder Zusicherungen in Bezug auf Inhalte oder Websites Dritter, einschließlich, aber nicht beschränkt auf die Genauigkeit, den Gegenstand, die Qualität oder die Aktualität.`,
  },
}
