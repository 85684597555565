import { variantProvider } from '../theme'
import { assignTextStyle } from './Text'

type States = ':last' | ''
export type ItemDotTimelineComposition = `wrapper${States}` | 'innerWrapper' | 'title' | 'description' | 'dot'
export type DotTimelineComposition = 'wrapper' | 'innerWrapper' | 'line' | `item${Capitalize<ItemDotTimelineComposition>}`

const createDotTimelineStyle = variantProvider.createVariantFactory<DotTimelineComposition>()

export const AppDotTimelineStyles = {
  default: createDotTimelineStyle((theme) => ({
    wrapper: {},
    innerWrapper: {
      height: 'fit-content',
      ...theme.presets.column,
      ...theme.spacing.gap(3),
      ...theme.presets.relative,
    },
    line: {
      ...theme.presets.absolute,
      ...theme.presets.fullHeight,
      ...theme.border.primary3({
        width: 1,
        style: 'dashed',
        directions: ['right'],
      }),
      width: 5.5,
      zIndex: 1,
    },

    itemWrapper: {
      ...theme.presets.relative,
      backgroundColor: theme.colors.neutral1,
    },
    'itemWrapper:last': {
      zIndex: 2,
    },
    itemDot: {
      ...theme.sized(1.25),
      ...theme.presets.absolute,
      borderRadius: theme.borderRadius.rounded,
      backgroundColor: theme.colors.primary3,
      zIndex: 3,
    },
    itemInnerWrapper: {
      ...theme.presets.column,
      ...theme.spacing.gap(2),
      ...theme.spacing.marginLeft(4),
    },
    itemTitle: {
      ...assignTextStyle('h3')(theme).text,
    },
    itemDescription: {
      ...assignTextStyle('p3')(theme).text,
    },
  })),
}
