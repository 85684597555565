import React from 'react'
import { StylesOf, useBooleanToggle, getNestedStylesByKey, PropsOf } from '@codeleap/common'
import { HeaderComposition } from '../../app/stylesheets/Header'
import { NavContent } from './Content'
import { ActionIcon, Drawer, Logo, View } from '@/components'

export type DrawerMenuProps = Partial<PropsOf<typeof Drawer>> & {
  isMobile?: boolean
  variantStyles?: StylesOf<HeaderComposition>
}

export const DrawerMenu = (props: DrawerMenuProps) => {
  const { isMobile, variantStyles, ...rest } = props

  const [drawerOpen, toggleDrawer] = useBooleanToggle(false)

  const MenuHamburguer = () => (
    <ActionIcon
      debugName='header:menuButton'
      icon='menu'
      styles={getNestedStylesByKey('menuIcon', variantStyles)}
      onPress={toggleDrawer}
    />
  )

  return (
    <>
      <Drawer
        styles={getNestedStylesByKey('drawer', variantStyles)}
        debugName='header:drawer'
        open={drawerOpen}
        position='top'
        toggle={toggleDrawer}
        {...rest}>
        <View variants={['fullWidth', 'justifySpaceBetween']}>
          <MenuHamburguer />
          <Logo debugName='drawer:logo' styles={getNestedStylesByKey('logo', variantStyles)} />
        </View>
        <NavContent isMobile={isMobile} variantStyles={variantStyles} />
      </Drawer>

      <MenuHamburguer />
    </>
  )
}
