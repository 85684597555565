import { AppForms, navigation } from '@/app'
import { APIClient } from '@/services'
import { useForm, useI18N } from '@codeleap/common'
import { Button, Checkbox, Text, TextInput, View, AuthFormComponents } from '@/components'
import { useKeydown } from '@codeleap/web'

export const LoginForm = () => {
  const form = useForm(AppForms.login, {})
  const { t } = useI18N()
  const { toggleRememberInformation } = APIClient.Session.useSession()

  const onSuccess = () => {
    navigation.navigate('Home')
  }

  const handleSocial = APIClient.Session.useSocialLogin((userSocial) => {
    if (userSocial) {
      onSuccess()
    }
  }, 'login')

  const login = APIClient.Session.useLogin()

  const handleSubmit = async () => {
    try {
      const successLogin = await login(form.values)

      if (successLogin) {
        onSuccess()
      }
    } catch (e) {
      logger.error(e)
    }
  }

  useKeydown('Enter', () => {
    const isInvalidSubmit = !form.isValid
    if (isInvalidSubmit) return
    handleSubmit?.()
  }, [form?.values])

  return (
    <View variants={['column', 'fullWidth']}>
      <Text variants={['h1', 'extraBold', 'marginBottom:1']} text={t('Login.title')} />
      <Text variants={['p1', 'marginBottom:4']} text={t('Login.subtitle')} />

      <View variants={['flex', 'column', 'gap:4', 'fullWidth']}>
        <View variants={['column', 'fullWidth']}>
          <TextInput
            {...form.register('email')}
            leftIcon={{ name: 'mail' }}
          />
          <TextInput
            {...form.register('password')}
            leftIcon={{ name: 'key' }}
            visibilityToggle
          />
          <Checkbox
            {...form.register('remember')}
            onValueChange={(val) => {
              toggleRememberInformation(val)
              form.setFieldValue('remember', val)
            }}
            variants={['left']}
          />
        </View>

        <AuthFormComponents.SocialProviders onPress={handleSocial} />

        <View variants={['column', 'gap:2']}>
          <Button
            onPress={handleSubmit}
            disabled={!form.isValid}
            debugName={`submit Login`}
            text={t('actions.continue')}
            variants={['marginHorizontal:auto', 'fullWidth', 'large', !form.isValid && 'outline']}
          />

          <Button
            onPress={() => navigation.navigate('Auth.ForgotPassword')}
            debugName={'navigate to forgot password Button'}
            variants={['outline', 'large', 'row', 'gap:1', 'alignCenter', 'justifyCenter', 'paddingHorizontal:2']}
          >
            <Text text={t('Login.forgot')} variants={['medium', 'alignSelfCenter', 'row', 'color:neutral10']} />
          </Button>
        </View>
      </View>

      <AuthFormComponents.RedirectLink
        route={'Auth.Signup'}
        text={`${t('Login.txt1')}`}
        textAction={`${t('actions.signup')}`}
      />
    </View>
  )
}
