import { Widget } from '@/utils'
import { ReactStateProps, useBooleanToggle } from '@codeleap/common'
import { View, Switch, Text } from '@/components'

type TSwitchWidget = Widget<ReactStateProps<'value', boolean>, {
  initialValue?: boolean
  onValueChange?: (value: boolean) => void
}>

export const SwitchWidget: TSwitchWidget = {
  Control({ value, setValue, label, args, onVariantsChange }) {

    function onValueChange(_value) {
      setValue(_value)
      args?.onValueChange?.(_value)
      onVariantsChange?.()
    }

    return <Switch
      value={value}
      label={label}
      onValueChange={onValueChange}
      debugName={'Switch Widget ' + label}
    />

  },
  handle(params) {
    const [value, setValue] = useBooleanToggle(params?.initialValue || false)

    return {
      value,
      setValue,
    }
  },
}
