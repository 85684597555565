import { assignTextStyle, createDefaultVariantFactory } from '@codeleap/common'
import { SectionFilterPresets, SectionFiltersComposition } from '@codeleap/web'

const createSectionFiltersStyle = createDefaultVariantFactory<SectionFiltersComposition>()

const defaultStyles = SectionFilterPresets

export const SectionFiltersStyles = {
  ...defaultStyles,
  default: createSectionFiltersStyle((theme) => ({
    wrapper: {
      ...theme.presets.column,
    },
    innerWrapper: {
      ...theme.spacing.paddingBottom(0),
      ...theme.presets.column,
      ...theme.presets.fullWidth,
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    label: {
      ...assignTextStyle('p2')(theme).text,
      ...theme.spacing.marginBottom(1),
    },
    optionWrapper: {
      ...theme.presets.column,
    },
    optionInnerWrapper: {
      ...theme.presets.column,
    },
    itemOptionButtonWrapper: {
      ...theme.spacing.marginBottom(1),
      ...theme.spacing.padding(1.5),
      borderRadius: theme.borderRadius.small,
      backgroundColor: theme.colors.neutral2,

      '&:hover': {
        backgroundColor: theme.colors.primary5,
      },
      '&:hover > p': {
        color: theme.colors.neutral1,
        fontWeight: 'bold',
      },
    },
    'itemOptionButtonWrapper:selected': {
      backgroundColor: theme.colors.primary4,
      'p': {
        color: theme.colors.neutral1,
        fontWeight: 'bold',
      },
    },
    footerWrapper: {
      gap: theme.spacing.value(1),
    },
    applyButtonWrapper: {
      ...theme.spacing.marginBottom(3),
      ...theme.spacing.marginTop(1),
      ...theme.spacing.padding(2),
      ...theme.presets.fullWidth,
    },
    applyButtonText: {
      ...assignTextStyle('p2')(theme).text,
      color: theme.colors.neutral1,
    },
    clearButtonWrapper: {
      ...theme.spacing.marginBottom(3),
      ...theme.spacing.marginTop(1),
      ...theme.spacing.padding(2),
      ...theme.presets.fullWidth,
    },
    clearButtonText: {
      ...assignTextStyle('p2')(theme).text,
      color: theme.colors.neutral1,
    },
  })),
}
