import { SwitchWidget } from '@/components'
import { APIClient } from '@/services'
import { useVariantSelector } from '@/utils'
import { onUpdate } from '@codeleap/common'

export const useCrudSettings = () => {
  const { Control: SettingsControl, handle: settings } = useVariantSelector([
    {
      id: 'optimisticDelete',
      label: 'Optimistic Delete',
      widget: SwitchWidget,
      args: {
        initialValue: true,
      },
    },
    {
      id: 'optimisticCreate',
      label: 'Optimistic Create',
      widget: SwitchWidget,
      args: {
        initialValue: true,
      },
    },
    {
      id: 'optimisticUpdate',
      label: 'Optimistic Update',
      widget: SwitchWidget,
      args: {
        initialValue: true,
      },
    },
    {
      id: 'optimisticLike',
      label: 'Optimistic Like',
      widget: SwitchWidget,
      args: {
        initialValue: true,
      },
    },
    {
      id: 'optimisticFavorite',
      label: 'Optimistic Favorite',
      widget: SwitchWidget,
      args: {
        initialValue: true,
      },
    },
    {
      id: 'throwErrorForDelete',
      label: 'Throw Error For Delete',
      widget: SwitchWidget,
      args: {
        initialValue: false,
      },
    },
  ])

  onUpdate(() => {
    APIClient.Post.postsManager.setOptions({
      meta: {
        optimisticLike: settings.optimisticLike.value,
        throwErrorForDelete: settings.throwErrorForDelete.value,
        optimisticFavorite: settings.optimisticFavorite.value,
      },
    })
  }, [settings.optimisticFavorite.value, settings.optimisticLike.value, settings.throwErrorForDelete.value])

  return {
    SettingsControl,
    settings,
  }
}
