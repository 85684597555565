import { AppImages, TCSS, ThemeBreakpoint } from '@/app'
import { ComponentVariants, PropsOf, useDefaultComponentStyle } from '@codeleap/common'
import { Image, Touchable } from '@/components'
import { LogoStyles, LogoComposition } from '../app/stylesheets/Logo'
import { StylesOf } from '@codeleap/web'
import { useMediaQueryDown, useToggleDevMode } from '@/utils'

type LogoProps = {
  styles?: StylesOf<LogoComposition>
  style?: TCSS
  switchServerOnPress?: boolean
  breakpoint?: ThemeBreakpoint
  responsive?: boolean
  image?: any
} & ComponentVariants<typeof LogoStyles> & Omit<PropsOf<typeof Touchable>, 'variants'|'styles'>

export function Logo(props: LogoProps) {
  const { responsiveVariants, variants, styles, switchServerOnPress, image = null, breakpoint = 'tabletSmall', responsive = true, ...rest } = props

  const isSmall = useMediaQueryDown(breakpoint)

  const logoImage = AppImages.LogoDooit

  // if (responsive) {
  //   logoImage = isSmall ? AppImages.LogoTemplateMobile : AppImages.LogoTemplate
  // }

  const { onPress } = useToggleDevMode()

  const variantStyles = useDefaultComponentStyle<'u:Logo', typeof LogoStyles>('u:Logo', {
    responsiveVariants,
    rootElement: 'wrapper',
    styles,
    variants,
  })

  const _image = <Image source={image ?? logoImage} css={[variantStyles.image]} />

  return (
    <Touchable
      debugName={'Logo'}
      {...rest}
      onPress={switchServerOnPress ? onPress : props.onPress}
      css={[variantStyles.wrapper, props?.style]}
    >
      {_image}
    </Touchable>
  )
}
