import { MID_BREAKPOINT, MOBILE_BREAKPOINT } from '@/app'
import { variantProvider } from '../theme'
import { ImageComposition } from './Image'
import { assignTextStyle } from './Text'

type States = ':left' | ':right' | ''
export type BulletPointComposition = `wrapper${States}` | `title${States}` | `description${States}` | `number${States}` | `numberWrapper${States}`
export type BulletPointsComposition =
  | 'wrapper'
  | 'innerWrapper'
  | 'leftWrapper'
  | 'rightWrapper'
  | `item${Capitalize<BulletPointComposition>}`
  | `image${Capitalize<ImageComposition>}`

const createBulletPointsStyle = variantProvider.createVariantFactory<BulletPointsComposition>()

export const AppBulletPointsStyles = {
  default: createBulletPointsStyle(theme => ({
    wrapper: {
      ...theme.spacing.gap(8),
      ...theme.presets.alignCenter,
      maxWidth: 1060,

      [theme.media.down(MID_BREAKPOINT)]: {
        ...theme.spacing.gap(4),
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.presets.column,
        ...theme.spacing.paddingHorizontal(2),
        ...theme.spacing.gap(4),
      },
    },

    leftWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.column,
      ...theme.spacing.gap(4),
    },

    rightWrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.column,
      ...theme.spacing.gap(4),
    },

    itemWrapper: {
      ...theme.presets.column,
      ...theme.spacing.gap(2),
    },
    'itemWrapper:left': {
      ...theme.presets.alignEnd,

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.presets.alignStart,
      },
    },
    itemNumberWrapper: {
      ...theme.spacing.padding(1),
      backgroundColor: theme.colors.neutral1,
      ...theme.presets.elevated,
      borderRadius: theme.borderRadius.midish,
      ...theme.sized(4.5),
      ...theme.presets.center,
    },
    itemNumber: {
      ...assignTextStyle('p2')(theme).text,
      color: theme.colors.primary3,
    },
    itemTitle: {
      ...assignTextStyle('h3')(theme).text,
    },
    'itemTitle:left': {
      ...theme.presets.textRight,

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.presets.textLeft,
      },
    },
    itemDescription: {
      ...assignTextStyle('p3')(theme).text,
    },
    'itemDescription:left': {
      ...theme.presets.textRight,

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.presets.textLeft,
      },
    },

    imageWrapper: {
      height: theme.spacing.value(65),

      [theme.media.down(MID_BREAKPOINT)]: {
        height: theme.spacing.value(50),
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        height: theme.spacing.value(65),
        order: -1,
      },
    },
  })),
}
