import { Settings } from '../app/Settings'
import { LoggerAnalytics } from '@codeleap/common'
import { getFirebase } from './firebaseApp'
import { QueryKeys } from './api/session/queryKeys'

const enabled = typeof window !== 'undefined' ? Settings.EnableAnalytics : false

export const AppAnalytics = new LoggerAnalytics.Analytics({
  init: async () => {
    if (!enabled) return

    const fb = await getFirebase()
    const analytics = fb.analytics()
    analytics.setAnalyticsCollectionEnabled(true)
    analytics.logEvent('page_view', {})

  },
  onEvent: async (eventArgs) => {
    if (!enabled) return

    const fb = await getFirebase()
    const analytics = fb.analytics()
    analytics.logEvent('app_action', eventArgs)

  },
  prepareData: () => {
    if (!enabled) return {}

    const extraData = { user: QueryKeys.me.getData() }
    return extraData
  },
  onInteraction: async (eventArgs) => {
    if (!enabled) return

    const fb = await getFirebase()
    const analytics = fb.analytics()
    analytics.logEvent('user_interaction', eventArgs)

  },
}, Settings)
