import { variantProvider } from '..'
import { TextEditorComposition, TextEditorPresets } from '@codeleap/web'
import { assignTextStyle } from '@codeleap/common'

const createTextEditorStyle =
  variantProvider.createVariantFactory<TextEditorComposition>()

const editorStyles = {
  default: createTextEditorStyle(theme => ({
    wrapper: {
      ...theme.spacing.paddingRight(1),
      ...theme.spacing.paddingLeft(3),
      ...theme.spacing.paddingVertical(2),
      fontFamily: theme.typography.base.fontFamily,
      fontSize: theme.typography.base.styles.p1.size,
      fontWeight: theme.typography.base.styles.p1.weight,
      borderBottomLeftRadius: theme.borderRadius.small,
      borderBottomRightRadius: theme.borderRadius.small,
      ...theme.border.neutral5({ width: 1, directions: ['bottom', 'left', 'right'] }),

      h1: { fontSize: theme.typography.base.styles.h1.size },
      h2: { fontSize: theme.typography.base.styles.h2.size },
      h3: { fontSize: theme.typography.base.styles.h3.size },
      h4: { fontSize: theme.typography.base.styles.h4.size },
      h5: { fontSize: theme.typography.base.styles.h5.size },
      h6: { fontSize: theme.typography.base.styles.h6.size },
      'ul, ol': {
        ...theme.spacing.marginLeft(3),
      },

      'p.placeholder::before': {
        color: theme.colors.neutral7,
        content: 'attr(data-placeholder)',
        float: 'left',
        height: 0,
        pointerEvents: 'none',
      },
    },
  })),
}

export const TextEditorStyles = {
  ...TextEditorPresets,
  default: createTextEditorStyle((theme) => ({
    wrapper: {
      ...theme.presets.flex,
      ...theme.presets.column,
    },
    'wrapper:error': {
      '.ProseMirror:focus': {
        outline: `auto 2px ${theme.colors.destructive2}`,
      },
    },
    editor: {
      ...editorStyles.default(theme).wrapper,
    },
    'editor:disabled': {
      ...editorStyles.default(theme).wrapper,
      cursor: 'not-allowed',
      ...theme.border.neutral2({ width: 1, directions: ['bottom', 'left', 'right'] }),
      color: theme.colors.neutral5,
    },
    'editor:error': {
      ...editorStyles.default(theme).wrapper,
      ...theme.border.destructive2({ width: 1, directions: ['bottom', 'left', 'right', 'top'] }),
    },
    'errorMessage:error': {
      ...assignTextStyle('p4')(theme).text,
      color: theme.colors.destructive2,
      ...theme.spacing.marginTop(1),
    },
    floatingMenu: {},
    bubbleMenu: {},
    bubbleMenuInnerWrapper: {},
    floatingMenuInnerWrapper: {},
  })),
}
