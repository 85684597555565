import { Navigation, Navigator } from '@codeleap/web'
import { navigate } from 'gatsby'
import { NavigateOptions } from '@reach/router'

export const routes = {
  // add a new route following the pattern of the others
  Home: '/',
  About: 'about',
  Contact: 'contact',

  Profile: {
    Edit: 'profile/edit',
  },
  Auth: {
    Login: 'auth/login',
    Signup: 'auth/signup',
    ForgotPassword: 'auth/forgot',
    VerifyEmail: 'auth/verifyEmail',
  },
  Playground: 'playground',
  Components: {
    View: 'components/{{component}}',
    List: 'components',
    Navigation: {
      Home: 'components/Navigation',
      Page1: 'components/Navigation/page-1',
      Page2: 'components/Navigation/page-2/{{id}}',
    },
  },
  Crud: {
    Example: 'crud',
    View: 'crud/{{id}}',
  },
} as const

const navigator: Navigator = (path, options) => navigate(path, options)

export const navigation = new Navigation<NavigateOptions<{}>, typeof routes>(routes, navigator)
