import { AppImages, assignTextStyle, I18N, LARGE_BREAKPOINT, MID_BREAKPOINT, MOBILE_BREAKPOINT, variantProvider } from '@/app'
import { CenterWrapper, DotTimeline, Image, Text, View } from '@/components'

const ITEMS_NUMBER = 4

export function RequesterSection() {
  const items = Array.from({ length: ITEMS_NUMBER }, (_, i) => ({
    title: I18N.t(`homeSections.requester.${i + 1}_title` as any),
    description: I18N.t(`homeSections.requester.${i + 1}_description` as any),
  }))

  return (
    <CenterWrapper
      variants={['sectionsPaddings']}
      styles={{
        wrapper: styles.centerWrapper,
        innerWrapper: styles.centerInnerWrapper,
      }}>
      <Text css={styles.text}>
        {I18N.t('homeSections.requester.title1') + '\u00A0'}
        <Text css={[styles.text, styles['text:primary3']]} text={I18N.t('homeSections.requester.title2')} />
        {'\u00A0' + I18N.t('homeSections.requester.title3')}
      </Text>
      <View css={styles.innerWrapper}>
        <Image source={AppImages.RequesterSection} css={styles.image} objectFit='contain' />
        <DotTimeline items={items} />
      </View>
    </CenterWrapper>
  )
}

const styles = variantProvider.createComponentStyle(
  theme => ({
    centerWrapper: {},
    centerInnerWrapper: {
      ...theme.presets.column,
      ...theme.presets.center,
    },
    innerWrapper: {
      ...theme.spacing.gap(10),
      ...theme.presets.center,
      maxWidth: theme.spacing.value(145),

      [theme.media.down(LARGE_BREAKPOINT)]: {
        ...theme.spacing.gap(8),
      },

      [theme.media.down(MID_BREAKPOINT)]: {
        ...theme.spacing.gap(4),
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.presets.column,
        ...theme.spacing.gap(3),
        ...theme.spacing.paddingHorizontal(2),
      },
    },
    image: {
      width: '60%',

      [theme.media.down(LARGE_BREAKPOINT)]: {
        width: '55%',
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.presets.fullWidth,
      },
    },

    text: {
      ...assignTextStyle('h0')(theme).text,
      display: 'inline',
      ...theme.presets.fullWidth,
      ...theme.presets.textCenter,

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...assignTextStyle('h2')(theme).text,
      },
    },

    'text:primary3': {
      color: theme.colors.primary3,

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        color: theme.colors.primary3,
      },
    },
  }),
  true,
)
