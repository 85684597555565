// NOTE This is the file were you put functions you don't know where else to put

import { I18NDictionary } from '@/app/i18n'

export function getRootLocale(locale) {
  const availableLocales = Object.keys(I18NDictionary).map(l => l.substring(0, 2))
  const _locale = locale.substring(0, 2)

  return availableLocales.includes(_locale) ? _locale : 'en'
}

export {}
