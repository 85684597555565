import { createForm, yup } from '@codeleap/common'
import { I18N } from '../i18n'

export const contact = createForm('post', {
  name: {
    type: 'text',
    label: I18N.t('form.labels.name'),
    placeholder: I18N.t('form.placeholders.name'),
    validate: yup.string().required(),
  },
  email: {
    type: 'text',
    label: I18N.t('form.labels.email'),
    placeholder: I18N.t('form.placeholders.email'),
    validate: yup.string().required().email(),
  },
  message: {
    type: 'text',
    label: I18N.t('form.labels.message'),
    placeholder: I18N.t('form.placeholders.message'),
    validate: yup.string().required(),
  },
})
