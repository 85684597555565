import { AppBulletPointsStyles } from '@/app/stylesheets'
import { BulletPointComposition, BulletPointsComposition } from '@/app/stylesheets/BulletPoints'
import { Image, Text, View } from '@/components'
import { ComponentVariants, getNestedStylesByKey, useDefaultComponentStyle } from '@codeleap/common'
import { StylesOf } from '@codeleap/web'

type BulletPointProps = {
  title: string
  description: string
  position?: number | string
  styles?: StylesOf<BulletPointComposition>
  side?: 'left' | 'right'
}

type BulletPointsProps = ComponentVariants<typeof AppBulletPointsStyles> & {
  styles?: StylesOf<BulletPointsComposition>
  items: BulletPointProps[]
  image: any
}

function BulletPoint({ title, description, styles, position, side }: BulletPointProps) {
  return (
    <View css={[styles.wrapper, styles[`wrapper:${side}`]]}>
      <View css={styles.numberWrapper}>
        <Text style={styles.number} text={`0${position}`} />
      </View>
      <Text style={[styles.title, styles[`title:${side}`]]} text={title} />
      <Text style={[styles.description, styles[`description:${side}`]]} text={description} />
    </View>
  )
}

export function BulletPoints({ items, image, variants, responsiveVariants, styles, ...props }: BulletPointsProps) {
  const half = Math.ceil(items.length / 2)
  const leftPoints = items.filter((_, i) => i + 1 <= half).map((item, i) => ({ ...item, position: i + 1 }))
  const rightPoints = items.filter((_, i) => i + 1 > half).map((item, i) => ({ ...item, position: i + 1 + half }))

  const variantStyles = useDefaultComponentStyle<'u:BulletPoints', typeof AppBulletPointsStyles>('u:BulletPoints', {
    variants,
    responsiveVariants,
    styles,
  })

  const itemStyles = getNestedStylesByKey('item', variantStyles)

  return (
    <View css={variantStyles.wrapper} {...props}>
      <View css={variantStyles.leftWrapper}>
        {leftPoints.map(i => (
          <BulletPoint {...i} key={`bulletpoint-item-${i.title}-${i.position}`} styles={itemStyles} side='left' />
        ))}
      </View>
      <Image source={image} objectFit='contain' style={variantStyles.imageWrapper} />
      <View css={variantStyles.rightWrapper}>
        {rightPoints.map(i => (
          <BulletPoint {...i} key={`bulletpoint-item-${i.title}-${i.position}`} styles={itemStyles} side='right' />
        ))}
      </View>
    </View>
  )
}
