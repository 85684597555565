import React, { useContext } from "react"
import { ComponentVariants, TypeGuards } from "@codeleap/common"
import { useSearchParams, UseSearchParamsReturn } from '@codeleap/web'
import { useLocation, WindowLocation } from "@reach/router"
import { PageProps } from "gatsby"
import { NavigationStyles } from '../../app/stylesheets'

type GetDynamicParamsProps<T extends string = string> = {
  defaultValues?: Partial<Record<T | undefined, string>>
}

type SearchParams = UseSearchParamsReturn<Record<string, string>>

type TNavigationContext<S = any> = {
  location: WindowLocation<S>
  searchParams: SearchParams[0]
  setSearchParams: SearchParams[1]
  resetSearchParams: SearchParams[2]
  searchParamsURL: SearchParams[3]
  url: string
  getDynamicParams: <T extends string = string>(listParams: Array<T>, props?: GetDynamicParamsProps<T>) => Record<T | undefined, string>
  params: PageProps['params']
  variants?: ComponentVariants<typeof NavigationStyles>['variants']
} & Partial<Omit<PageProps, 'children'>>

const NavigationContext = React.createContext({} as TNavigationContext)

type NavigationContainerProps = Partial<Omit<PageProps, 'children'>> & {
  children?: React.ReactNode
  variants?: ComponentVariants<typeof NavigationStyles>['variants']
}

export function NavigationContainer(props: NavigationContainerProps) {
  const {
    children,
    params = {},
    ...rest
  } = props

  const location = useLocation()

  const [
    searchParams, 
    setSearchParams, 
    resetSearchParams, 
    searchParamsURL
  ] = useSearchParams()

  function getDynamicParams<T extends string = string>(listParams: Array<T>, props: GetDynamicParamsProps<T>): Record<T | undefined, string> {
    const {
      defaultValues = {}
    } = props

    const parseParams = (arr: string[]) => {
      let result = {}

      listParams?.forEach((name, i) => {
        const value = arr?.[i] as string

        const isNil = TypeGuards.isNil(value) || value?.length <= 0

        const defaultValue = defaultValues?.[name as any] ?? null

        result = {
          ...result,
          [name]: isNil ? defaultValue : value
        }
      })

      return result as any
    }

    if (!TypeGuards.isNil(params)) {
      const isDynamicParams = Object.keys(params)?.includes('*') 
      
      const _params = isDynamicParams
        ? Object.values(params)?.[0]?.split('/') 
        : params

      return parseParams(Object.values(_params))
    }

    return parseParams([])
  }

  const value: TNavigationContext = {
    ...rest,
    location,
    searchParams,
    setSearchParams,
    resetSearchParams,
    searchParamsURL,
    url: location.href,
    getDynamicParams,
    params,
  }

  return <NavigationContext.Provider value={value}>
    {children}
  </NavigationContext.Provider>
}

export const useNavigation = () => {
  const ctx = useContext(NavigationContext)

  if (!ctx) {
    throw new Error("NavigationContext not found")
  }

  return ctx
}
