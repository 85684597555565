import { AiOutlineBold, AiOutlineItalic, AiOutlineUnderline, AiOutlineStrikethrough, AiOutlineUnorderedList, AiOutlineOrderedList } from 'react-icons/ai'
import { FaAlignCenter, FaAlignJustify, FaAlignLeft, FaAlignRight, FaImage, FaRegFileAlt } from 'react-icons/fa'
import { LuHeading1, LuHeading2, LuHeading3, LuHeading4, LuHeading5, LuHeading6, LuLink2, LuHighlighter } from 'react-icons/lu'
import { ToolbarOption } from './types'
import { Theme } from '@/app'
import { ColorPicker } from '@codeleap/web'

const iconSize = Theme.values.iconSize[1]
const iconColor = Theme.colors.light.neutral9
export const toolbarOptions: ToolbarOption[][] = [
  [
    {
      id: 'bold',
      icon: <AiOutlineBold size={iconSize} color={iconColor}/>,
      action: ({ editor }) => editor?.chain().focus().toggleBold()
        .run(),
    },
    {
      id: 'italic',
      icon: <AiOutlineItalic size={iconSize} color={iconColor}/>,
      action: ({ editor }) => editor?.chain().focus().toggleItalic()
        .run(),
    },
    {
      id: 'underline',
      icon: <AiOutlineUnderline size={iconSize} color={iconColor}/>,
      action: ({ editor }) => editor?.chain().focus().toggleUnderline()
        .run(),
    },
    {
      id: 'strike',
      icon: <AiOutlineStrikethrough size={iconSize} color={iconColor}/>,
      action: ({ editor }) => editor?.chain().focus().toggleStrike()
        .run(),
    },
    {
      id: 'highlight',
      icon: <LuHighlighter size={iconSize} color={iconColor}/>,
      custom: ({ defaultBtn, option, editor }) => (
        <ColorPicker
          key={option.id}
          openPickerComponent={({ toggle }) => defaultBtn({ onPress: toggle })}
          onConfirm={(color) => option.action({ editor, color })}
        />
      ),
      action: ({ editor, color }) => {
        const chain = editor?.chain().focus()
        !editor.isActive('highlight') ? chain.setHighlight({ color }).run() : chain.unsetHighlight().run()
      },
    },
  ],
  [
    {
      id: 'h1',
      icon: <LuHeading1 size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive('heading', { level: 1 }),
      action: ({ editor }) => editor?.chain().focus().toggleHeading({ level: 1 })
        .run(),
    },
    {
      id: 'h2',
      icon: <LuHeading2 size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive('heading', { level: 2 }),
      action: ({ editor }) => editor?.chain().focus().toggleHeading({ level: 2 })
        .run(),
    },
    {
      id: 'h3',
      icon: <LuHeading3 size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive('heading', { level: 3 }),
      action: ({ editor }) => editor?.chain().focus().toggleHeading({ level: 3 })
        .run(),
    },
    {
      id: 'h4',
      icon: <LuHeading4 size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive('heading', { level: 4 }),
      action: ({ editor }) => editor?.chain().focus().toggleHeading({ level: 4 })
        .run(),
    },
    {
      id: 'h5',
      icon: <LuHeading5 size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive('heading', { level: 5 }),
      action: ({ editor }) => editor?.chain().focus().toggleHeading({ level: 5 })
        .run(),
    },
    {
      id: 'h6',
      icon: <LuHeading6 size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive('heading', { level: 6 }),
      action: ({ editor }) => editor?.chain().focus().toggleHeading({ level: 6 })
        .run(),
    },
  ],
  [
    {
      id: 'left',
      icon: <FaAlignLeft size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive({ textAlign: 'left' }),
      action: ({ editor }) => editor?.chain().focus().setTextAlign('left')
        .run(),
    },
    {
      id: 'center',
      icon: <FaAlignCenter size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive({ textAlign: 'center' }),
      action: ({ editor }) => editor?.chain().focus().setTextAlign('center')
        .run(),
    },
    {
      id: 'justify',
      icon: <FaAlignJustify size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive({ textAlign: 'justify' }),
      action: ({ editor }) => editor?.chain().focus().setTextAlign('justify')
        .run(),
    },
    {
      id: 'right',
      icon: <FaAlignRight size={iconSize} color={iconColor}/>,
      isActive: ({ editor }) => editor?.isActive({ textAlign: 'right' }),
      action: ({ editor }) => editor?.chain().focus().setTextAlign('right')
        .run(),
    },
  ],
  [
    {
      id: 'bulletList',
      icon: <AiOutlineUnorderedList size={iconSize} color={iconColor}/>,
      action: ({ editor }) => editor?.chain().focus().toggleBulletList()
        .run(),
    },
    {
      id: 'orderedList',
      icon: <AiOutlineOrderedList size={iconSize} color={iconColor}/>,
      action: ({ editor }) => editor?.chain().focus().toggleOrderedList()
        .run(),
    },
  ],
  [
    {
      id: 'link',
      icon: <LuLink2 size={iconSize} color={iconColor}/>,
      action: ({ editor }) => {
        const { href } = editor.getAttributes('link') || { href: '' }
        const selectedText = window.getSelection().toString().replace(/\s/g, '')
        const isValidURL = /^(?:https?:\/\/|www\.)\S+/.test(selectedText)

        const chain = editor.chain().focus()

        if (selectedText && isValidURL && (href === '' || href !== selectedText)) {
          return chain.setLink({ href: selectedText }).run()
        } else {
          return chain.unsetLink().run()
        }
      },
    },
    {
      id: 'image',
      icon: <FaImage size={iconSize} color={iconColor} />,
      action: async ({ editor, fileInputRef }) => {
        const selectedText = window.getSelection().toString().replace(/\s/g, '')
        const isValidURL = /^(?:https?:\/\/)\S+/.test(selectedText)

        const chain = editor.chain().focus()

        if (selectedText && isValidURL) {
          return chain.setImage({ src: selectedText }).run()
        }

        if (!selectedText) {
          const [image] = await fileInputRef?.current?.openFilePicker()
          if (image.file.type.startsWith('image/')) {
            const url = URL.createObjectURL(image.file)
            // save the image somewhere
            /*@ts-ignore*/
            return chain.setImage({ src: url, 'data-uploaded': true }).run()
          }
        }
      },
    },
    {
      id: 'fileComponent',
      icon: <FaRegFileAlt size={iconSize} color={iconColor}/>,
      action: async ({ editor, fileInputRef }) => {
        const chain = editor.chain().focus()
        const file = await fileInputRef?.current?.openFilePicker()
        return chain.setAttachment({ file: file[0], 'data-uploaded': true }).run()
      },
    },
  ],
]
