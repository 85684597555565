import { LARGE_BREAKPOINT, MID_BREAKPOINT, MOBILE_BREAKPOINT } from '@/app'
import { variantProvider } from '../theme'
import { includePresets } from '@codeleap/common'
import { assignTextStyle } from './Text'
import { LogoComposition } from './Logo'

export type FooterComposition =
  'wrapper' |
  'innerWrapper' |
  `logo${Capitalize<LogoComposition>}` |
  'separator' |
  'content' |
  'topContent' |
  'bottomContent' |
  'contactWrapper' |
  'contactText' |
  'disclaimersWrapper' |
  'disclaimerText' |
  'socialMediaWrapper' |
  'navigateWrapper' |
  'navigateColumn' |
  'navigateTitle' |
  'navigateLink'

const createFooterStyle = variantProvider.createVariantFactory<FooterComposition>()

const presets = includePresets((s) => createFooterStyle(() => ({ wrapper: s })))

const minHeight = 420

export const AppFooterStyles = {
  ...presets,
  default: createFooterStyle((theme) => ({
    wrapper: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      backgroundColor: theme.colors.footerBackground,
      minHeight: minHeight,
      ...theme.presets.fullWidth,

      [theme.media.down(MID_BREAKPOINT)]: {
        ...theme.spacing.paddingVertical(10),
        ...theme.spacing.paddingHorizontal(4),
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.spacing.paddingHorizontal(0),
      },
    },
    innerWrapper: {
      ...theme.presets.column,

      [theme.media.up('desktopHuge')]: {
        ...theme.spacing.paddingHorizontal(0),
      },

      [theme.media.down('desktopLarge')]: {
        ...theme.spacing.paddingHorizontal(0),
      },
    },
    content: {
      ...theme.presets.row,
      ...theme.presets.flex,
      ...theme.presets.justifySpaceBetween,
      gap: theme.spacing.value(5),

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.presets.column,
      },
    },
    navigateWrapper: {
      gap: theme.spacing.value(2.5),

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.presets.column,
      },
    },
    navigateColumn: {
      width: theme.spacing.value(22.5),
      ...theme.presets.column,
      gap: theme.spacing.value(1),

      [theme.media.down(LARGE_BREAKPOINT)]: {
        width: theme.spacing.value(17.5),
      },

      [theme.media.down(MID_BREAKPOINT)]: {
        width: theme.spacing.value(12.5),
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.presets.fullWidth,
        ...theme.presets.center,
      },
    },
    navigateTitle: {
      ...assignTextStyle('h4')(theme).text,
      color: theme.colors.neutral2,
    },
    navigateLink: {
      ...assignTextStyle('p1')(theme).text,
      color: theme.colors.neutral6,
      textDecoration: 'none',
    },
  })),
}
