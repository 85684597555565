import React from 'react'
import { Button, Text, View } from "@/components"
import { navigation } from '@/app'

export const ForgotPasswordAfterForm = () => {
  const onTryResendEmail = () => {
    navigation.navigate('Auth.ForgotPassword')
  }

  return (
    <View variants={['flex', 'column', 'gap:4']}>
      <View variants={['column', 'gap:1']}>
        <Text
          variants={['h1', 'extraBold']}
          text={'The email has been sent'}
        />

        <Text variants={['p1']} text={'Check your email for the password reset link.'} />
      </View>

      <View variants={['column', 'gap:2', 'marginTop:auto', 'alignSelfCenter', 'fullWidth']}>
        <Button onPress={onTryResendEmail} debugName={'Resend email'} variants={['outline', 'pill', 'large', 'row', 'gap:0.5', 'alignCenter', 'justifyCenter', 'paddingHorizontal:2']}>
          <Text text={'Did not arrive?'} variants={['medium', 'alignSelfCenter', 'row', 'color:neutral10']} />
          <Text text={'Resend'} variants={['medium', 'color:primary3']} />
        </Button>

        <Button text='Go back to Login' onPress={() => navigation.navigate('Auth.Login')} />
      </View>
    </View>
  )
}
