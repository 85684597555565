import { LARGE_BREAKPOINT, MID_BREAKPOINT, MOBILE_BREAKPOINT } from '@/app'
import { variantProvider } from '../theme'
import { ImageComposition } from './Image'
import { assignTextStyle } from './Text'

export type JobCardComposition = 'wrapper' | 'innerWrapper' | 'title' | `image${Capitalize<ImageComposition>}` | 'icon'
export type JobCardsComposition = 'wrapper' | 'innerWrapper' | `card${Capitalize<JobCardComposition>}`

const createJobCardsStyle = variantProvider.createVariantFactory<JobCardsComposition>()

export const AppJobCardsStyles = {
  default: createJobCardsStyle(theme => ({
    wrapper: {
      ...theme.presets.row,
      ...theme.presets.center,
      ...theme.presets.wrap,
      maxWidth: theme.spacing.value(125),
      ...theme.spacing.gap(4),

      [theme.media.down(LARGE_BREAKPOINT)]: {
        ...theme.spacing.gap(3.5),
      },

      [theme.media.down(MID_BREAKPOINT)]: {
        ...theme.spacing.gap(2.5),
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        gridTemplateColumns: '1fr 1fr',
        ...theme.spacing.gap(2),
      },
    },

    cardWrapper: {
      maxWidth: '31%',
      ...theme.presets.relative,
      ...theme.presets.column,
      aspectRatio: '1 / 1',

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        maxWidth: '45%',
      },
    },
    cardInnerWrapper: {
      ...theme.presets.fullWidth,
      backgroundColor: theme.colors.neutral1,
      height: 72,
      ...theme.spacing.gap(1),
      ...theme.presets.center,
      borderBottomLeftRadius: theme.borderRadius.medium,
      borderBottomRightRadius: theme.borderRadius.medium,

      [theme.media.down(MID_BREAKPOINT)]: {
        height: 48,
        borderBottomLeftRadius: theme.borderRadius.midish,
        borderBottomRightRadius: theme.borderRadius.midish,
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        height: 36,
      },
    },
    cardTitle: {
      ...assignTextStyle('h3')(theme).text,
      color: theme.colors.neutral9,

      [theme.media.down(MID_BREAKPOINT)]: {
        ...assignTextStyle('p2')(theme).text,
        fontWeight: 500,
        color: theme.colors.neutral9,
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...assignTextStyle('p4')(theme).text,
        color: theme.colors.neutral9,
      },
    },
    cardImageWrapper: {
      ...theme.presets.fullWidth,
      height: 'calc(100% - 72px)',
      borderTopLeftRadius: theme.borderRadius.medium,
      borderTopRightRadius: theme.borderRadius.medium,

      [theme.media.down(MID_BREAKPOINT)]: {
        height: 'calc(100% - 48px)',
        borderTopLeftRadius: theme.borderRadius.midish,
        borderTopRightRadius: theme.borderRadius.midish,
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        height: 'calc(100% - 36px)',
      },
    },
    cardIcon: {
      ...theme.sized(4),
      color: theme.colors.primary3,

      [theme.media.down(MID_BREAKPOINT)]: {
        ...theme.sized(2.5),
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.sized(2),
      },
    },
  })),
}
