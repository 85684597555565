import { AppAchievementsCardStyles } from '@/app/stylesheets'
import { AchievementCardComposition, AchievementsCardComposition } from '@/app/stylesheets/AchievementsCard'
import { Icon, Text, View } from '@/components'
import { ComponentVariants, useDefaultComponentStyle, StylesOf, getNestedStylesByKey } from '@codeleap/common'

type AchievementProps = {
  number: number
  text: string
  styles?: StylesOf<AchievementCardComposition>
}

type AchievementsCardProps = ComponentVariants<typeof AppAchievementsCardStyles> & {
  styles?: StylesOf<AchievementsCardComposition>
  style?: StyleSheet
  achievements: AchievementProps[]
  title: string
}

function Achievement({ number, text, styles }: AchievementProps) {
  return (
    <View css={styles.wrapper}>
      <Text text={`${number}+`} css={styles.number} />
      <Text text={text} css={styles.text} />
    </View>
  )
}

export function AchievementsCard({ achievements, title, variants, responsiveVariants, styles, style }: AchievementsCardProps) {
  const variantStyles = useDefaultComponentStyle<'u:AchievementsCard', typeof AppAchievementsCardStyles>(
    'u:AchievementsCard',
    {
      variants,
      responsiveVariants,
      styles,
    },
  )

  const cardStyles = getNestedStylesByKey('card', variantStyles)

  return (
    <View css={[variantStyles.wrapper, style]}>
      <Text css={variantStyles.title} text={title} />
      <View css={variantStyles.innerWrapper}>
        {achievements.map((a, i) => (
          <Achievement key={`achievement-card-${a.text}-${i}`} {...a} styles={cardStyles} />
        ))}
      </View>
      <Icon name='door' style={variantStyles.icon} debugName='door-icon' />
    </View>
  )
}
