export const privacy = {
  en: {
    title: 'Privacy policy',
    content: `Privacy Policy of Dooit App Sàrl

Version 1.0 – 08 August 2024

About us

This privacy policy ("Privacy Policy") explains how we process and protect your personal data when you use Dooit's website available on https://dooitapp.ch/ ("Website") or Dooit's app ("App"; the Website and the App together referred as "Platform") or our services provided via the Platform by Dooit (together, the "Services")

These Services are operated by Dooit App Sàrl, ZA La Pièce 1-A5, A-One Business Center, 1180 Rolle (the "Company", "we", "our", or "us"). The Company is the controller for the data processing described below.

Unless otherwise defined in this Privacy Policy, the definitions used in this Privacy Policy have the same meaning as in the Swiss Federal Act on Data Protection (FADP) or the EU General Data Protection Regulation (GDPR).


1	Personal data we collect

We may collect or receive personal information for a number of purposes connected with our business operations when you use our Services, namely as listed in 1.1 and 1.2 below 

There is no obligation to provide your personal data. However, please note that our Services cannot be provided if you do not provide the required data strictly necessary for performing the contract between you and us.

1.1	On the Website

•	Usage and analytics information (e.g., identifiers, numbers of clicks, tracking data)
•	Contact details (e.g., name, address, phone number, birth date)
•	Request details (e.g., details and content of your inquiries)
•	Website visitor details (e.g., IP address, logfiles, terminal ID)

1.2	On the App

•	Usage and analytics information (e.g., identifiers, numbers of clicks, tracking data)
•	Contact details (e.g., name, address, phone number, birth date)
•	Geolocation (e.g., your actual location, GPS data)
•	Login details (e.g., password, username, session)
•	Request details (e.g., details and content of your inquiries)
•	Chat details (e.g.. information communicated via the chat function of the App)
•	App visitor details (e.g., IP address, logfiles, terminal ID)


2	How we collect personal data

We collect information about our users when they use our Services, including taking certain actions within it.

Directly

•	Via the Website and/or the App and electronic communication
•	When you use our Services
•	When you provide services to us
•	When you correspond with us by electronic means using our Services
•	When you browse, complete a form or make an inquiry while using our Services

Indirectly

•	Through public sources
•	From public registers (such as commercial registers), news articles and internet searches
•	When our business customers engage us to perform professional services which involve them sharing personal data they control with us as part of that engagement
•	From external Service Providers (see section 5)


3	Legal Basis and purposes

Our legal basis for collecting and using the personal data described in this Privacy Policy depends on the personal data we collect and the specific purposes for which we collect it.

3.1	On the Website 

Consent: We may rely on your freely given consent at the time you provided your personal data. In particular:

•	To analyse, improve, personalise and monitor the usage of our Services and communication
•	To place non-essential cookies and other tools on your browser
•	To provide users with news, special offers, newsletters, and general information about goods and services which we offer

Legitimate interests: We rely on legitimate interests based on our assessment that the processing is fair and reasonable and does not override your interests or fundamental rights and freedoms. In particular:

•	To place cookies and other tools on your browser 
•	To develop new services
•	To maintain and improve our Services, as well as to detect, prevent, and address security threats

3.2	On the App

Contract: To perform our contractual obligations or take steps linked to a contract with you. In particular:

•	To provide you with customer support
•	To set up and manage your account, as well as to verify your credentials when logging in
•	To provide our Services
•	To enable the provision of the services provided by the service providers on the App 

Consent: We may rely on your freely given consent at the time you provided your personal data. In particular:

•	To analyse, improve, personalise and monitor the usage of our Services and communication
•	To provide users with news, special offers, newsletters, and general information about goods and services which we offer

Legitimate interests: We rely on legitimate interests based on our assessment that the processing is fair and reasonable and does not override your interests or fundamental rights and freedoms. In particular:

•	To place cookies and other tools on your browser 
•	To develop new services
•	To maintain and improve our Services, as well as to detect, prevent, and address security threats

Necessity for compliance with legal obligations: To meet regulatory and public interest obligations. In particular:

•	To notify you about changes to our Services and our Privacy Policy
•	To comply with applicable regulations and legislation.
•	For the legal enforcement of claims and rights


4	Data retention

We retain personal data for so long as it is needed for the purposes for which it was collected and in line with legal and regulatory requirements or contractual arrangements. After this period, we delete or fully anonymize your personal data.


5	Data recipients

We engage third-party companies ("Service Providers") to facilitate the operation of our Services, assist in analysing the usage of the Services, or perform necessary services, such as payment and the provision of IT services. These third parties have access to your personal data only to the extent necessary to perform these tasks. 

Type(s) of Service Providers who might access your personal data:

•	Third parties who provide IT and software services
•	Third parties who help us with client insights and marketing


6	Data transfers

We and/or our Service Providers may transfer your personal data to and process it in the following countries:

•	EU and EEA
•	USA

We may use Service Providers partly located in so-called third countries (outside the European Union or the European Economic Area or Switzerland) or process personal data there, i.e., countries whose level of data protection does not correspond to that of the EU or Switzerland.

We safeguard your personal data per our contractual obligations and applicable data protection legislation when transferring data abroad. 

Such safeguards may include:

•	The transfer to countries that have been deemed to provide an adequate level of protection according to the Federal Council, as well as to countries where there is an adequacy decisions by the European Commission in place
•	Applying standard data protection model clauses, binding corporate rules or other standard contractual obligations that provide appropriate data protection

If a third country transfer takes place and there is no adequacy decision or appropriate safeguards, it is possible and there is a risk that authorities in the third country (e.g. intelligence services) can gain access to the transferred data and that the enforceability of your data subject's rights cannot be guaranteed.


7	Data disclosure

We may disclose your personal data in the good faith belief that such action is necessary:

•	To comply with a legal obligation (i.e., if required by law or in response to valid requests by public authorities, such as a court or government agency)
•	To protect the security of our Services and defend our rights or property
•	To prevent or investigate possible wrongdoing in connection with us


8	Data Security

We take reasonable technical and organisational security measures that we deem appropriate to protect your stored data against manipulation, loss, or unauthorised third-party access. Our security measures are continually adapted to technological developments.

We also take internal data privacy very seriously. Our employees and the service providers that we engage are required to maintain secrecy and comply with applicable data protection legislation. In addition, they are granted access to personal data only insofar as this is necessary for them to carry out their respective tasks or mandate.

The security of your personal data is important to us but remember that no method of transmission over the internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal data, we cannot guarantee its absolute security. We recommend using antivirus software, a firewall, and other similar software to safeguard your system.


9	Your rights

You have the below data protection rights. To exercise these rights, you may contact the above address or send an e-mail to: info@dooitapp.ch. Please note that we may ask you to verify your identity before responding to such requests.

•	Right of access: You have a right to request a copy of your personal data, which we will provide to you in an electronic form.
•	Right to amendment: You have the right to ask us to correct our records if you believe they contain incorrect or incomplete information about you.
•	Right to withdraw consent: If you have provided your consent to the processing of your personal data, you have the right to withdraw your consent at any time with effect for the future. This includes cases where you wish to opt-out from marketing communications. Once we have received notification that you have withdrawn your consent, we will no longer process your information for the purpose(s) to which you initially consented to unless there is another legal basis for processing. To stop receiving emails from us, please click on the 'unsubscribe' link in the email you received or contact us at info@dooitapp.ch.
•	Right to erasure: You have the right to request that we delete your personal data when it is no longer necessary for the purposes for which it was collected or when it was unlawfully processed.
•	Right to restriction of processing: You have the right to request the restriction of our processing of your personal data where you believe it to be inaccurate, our processing is unlawful, or where we no longer need to process it for the initial purpose, but where we are not able to delete it due to a legal obligation or because you do not want us to delete it.
•	Right to portability: You have the right to request that we transmit your personal data to another data controller in a standard format such as Excel, if this is data which you have provided to us and if we are processing it on the legal basis of your consent or to perform our contractual obligations.
•	Right to object to processing: Where the legal basis for our processing of your personal data is our legitimate interest, you have the right to object to such processing on grounds relating to your particular situation. We will abide by your request unless we have a compelling legal basis for the processing which overrides your interests or if we need to continue to process the personal data for the exercise or defence of a legal claim.
•	Right to lodge a complaint with a supervisory authority: You have the right of appeal to a data protection supervisory authority if you believe that the processing of your personal data violates data protection law. The competent data protection authority in Switzerland is the Federal Data Protection and Information Commissioner (www.edoeb.admin.ch/edoeb/en/home.html). In the EU and EEA, you can exercise this right, for example, before a supervisory authority in the Member State of your residence, your place of work or the place of the alleged infringement. You can find a list of the relevant authorities here: https://edpb.europa.eu/about-edpb/board/members_en.


10	Social Media and Links to Third-Party Apps and Websites

Our Services contain links to websites or apps that are not operated by us. When you click on a third-party link, you will be directed to that third party's website or app. We have no control over the content, privacy policies, or practices of any third-party websites or services.

We maintain online presences on social networks to, among other things, communicate with customers and prospective customers and to provide information about our products and Services. If you have an account on the same network, it is possible that your information and media made available there may be seen by us, for example, when we access your profile. In addition, the social network may allow us to contact you. As soon as we transfer personal data into our own system, we are responsible for this independently. This is then done to carry out pre-contractual measures and to fulfil a contract. For the legal basis of the data processing carried out by the social networks under their own responsibility, please refer to their data protection declarations. Below is a list of social networks on which we operate an online presence:

•	Facebook: Privacy Policy
•	Instagram: Privacy Policy


11	Newsletter

We send newsletters and other notifications by email and through other communication channels and may deliver them with the help of third parties.

In principle, you must expressly consent to receive newsletters and other notifications from us, unless this is permitted for other legal reasons. 

Newsletters and other notifications may contain web links or tracking pixels that record whether an individual newsletter or notification has been opened and which web links were clicked (performance measurement). Such web links and tracking pixels record the use of newsletters and other notifications. We use this statistical recording of usage, including success and reach measurement, in order to be able to offer newsletters and other notifications effectively and in a user-friendly manner, as well as permanently, securely and reliably, based on the reading habits of the recipients.

You can unsubscribe from newsletters and other notifications at any time and thereby object in particular to the aforementioned collection of usage. You can do so by contacting us directly or following the link included in the footer of each newsletter we send you.


12	Changes to this Privacy Policy

We may update our Privacy Policy from time to time. We therefore encourage you to review this Privacy Policy periodically for any changes. 

Changes to this Privacy Policy are effective when they are posted on this page.


13	Contact us

If you have any questions about this Privacy Policy, do not hesitate to get in touch with us at: 

Dooit App Sàrl, 

ZA La Pièce 1-A5, A-One Business Center, 1180 Rolle,

info@dooitapp.ch.`,
  },
  fr: {
    title: 'Politique de confidentialité',
    content: `Politique de confidentialité de Dooit App Sàrl

Version 1.0 - 08 août 2024

A propos de nous

Cette politique de confidentialité (" Politique de confidentialité ") explique comment nous traitons et protégeons vos données personnelles lorsque vous utilisez le site web de Dooit disponible sur https://dooitapp.ch/ (" Site web ") ou l'application de Dooit (" App " ; le Site web et l'App sont désignés ensemble comme la " Plateforme ") ou nos services fournis via la Plateforme par Dooit (ensemble, les " Services ").

Ces Services sont exploités par Dooit App Sàrl, ZA La Pièce 1-A5, A-One Business Center, 1180 Rolle (la "Société", "nous", "notre", ou "nos"). La Société est le responsable du traitement des données décrit ci-dessous.

Sauf indication contraire, les définitions utilisées dans la présente politique de confidentialité ont la même signification que dans la loi fédérale suisse sur la protection des données (LFPD) ou le règlement général sur la protection des données (RGPD) de l'UE.


1	Données personnelles collectées

Nous pouvons collecter ou recevoir des informations personnelles pour un certain nombre de raisons liées à nos activités commerciales lorsque vous utilisez nos services, notamment celles énumérées dans les paragraphes suivants 1.1 et 1.2 ci-dessous 

Il n'y a aucune obligation de fournir vos données personnelles. Toutefois, veuillez noter que nos services ne peuvent pas être fournis si vous ne fournissez pas les données requises strictement nécessaires à l'exécution du contrat entre vous et nous.

1.1	Sur le site web

•	Informations relatives à l'utilisation et à l'analyse (par exemple, identifiants, nombre de clics, données de suivi)
•	Coordonnées (nom, adresse, numéro de téléphone, date de naissance)
•	Détails de la demande (par exemple, détails et contenu de vos demandes)
•	Données relatives aux visiteurs du site web (par exemple, adresse IP, fichiers journaux, identifiant du terminal)

1.2	Sur l'application

•	Informations relatives à l'utilisation et à l'analyse (par exemple, identifiants, nombre de clics, données de suivi)
•	Coordonnées (nom, adresse, numéro de téléphone, date de naissance)
•	Géolocalisation (par exemple, votre position réelle, données GPS)
•	Données de connexion (par exemple, mot de passe, nom d'utilisateur, session)
•	Détails de la demande (par exemple, détails et contenu de vos demandes)
•	Détails du chat (par exemple, informations communiquées via la fonction de chat de l'application)
•	Détails sur les visiteurs de l'application (par exemple, adresse IP, fichiers journaux, identifiant du terminal)


2	Comment nous collectons les données personnelles

Nous recueillons des informations sur nos utilisateurs lorsqu'ils utilisent nos services, y compris lorsqu'ils y effectuent certaines actions.

Directement

•	Via le site web et/ou l'application et la communication électronique
•	Lorsque vous utilisez nos services
•	Lorsque vous nous fournissez des services
•	lorsque vous correspondez avec nous par voie électronique en utilisant nos services
•	lorsque vous naviguez, remplissez un formulaire ou faites une demande lors de l'utilisation de nos services

Indirectement

•	Par le biais de sources publiques
•	à partir de registres publics (tels que les registres du commerce), d'articles de presse et de recherches sur l'internet
•	Lorsque nos entreprises clientes nous engagent pour fournir des services professionnels qui les amènent à partager avec nous des données à caractère personnel qu'elles contrôlent dans le cadre de cet engagement
•	Par des prestataires de services externes (voir section 5)


3	Base juridique et objectifs

Notre base juridique pour la collecte et l'utilisation des données à caractère personnel décrites dans la présente politique de confidentialité dépend des données à caractère personnel que nous collectons et des objectifs spécifiques pour lesquels nous les collectons.

3.1	Sur le site web 

Consentement : Nous pouvons nous appuyer sur le consentement que vous avez donné librement au moment où vous avez fourni vos données à caractère personnel. En particulier :

•	analyser, améliorer, personnaliser et contrôler l'utilisation de nos services et de notre communication
•	Placer des cookies non essentiels et d'autres outils sur votre navigateur
•	Fournir aux utilisateurs des nouvelles, des offres spéciales, des bulletins d'information et des informations générales sur les biens et les services que nous proposons.

Intérêts légitimes : Nous nous fondons sur des intérêts légitimes en estimant que le traitement est équitable et raisonnable et qu'il ne l'emporte pas sur vos intérêts ou vos libertés et droits fondamentaux. En particulier :

•	Placer des cookies et d'autres outils sur votre navigateur 
•	Développer de nouveaux services
•	pour maintenir et améliorer nos services, ainsi que pour détecter, prévenir et traiter les menaces à la sécurité

3.2	Sur l'application

Contrat : pour exécuter nos obligations contractuelles ou prendre des mesures liées à un contrat avec vous. En particulier :

•	Pour vous fournir un service d'assistance à la clientèle
•	Pour créer et gérer votre compte, ainsi que pour vérifier vos informations d'identification lorsque vous vous connectez.
•	Fournir nos services
•	Pour permettre la fourniture des services offerts par les fournisseurs de services sur l'application. 

Consentement : Nous pouvons nous appuyer sur le consentement que vous avez donné librement au moment où vous avez fourni vos données à caractère personnel. En particulier :

•	analyser, améliorer, personnaliser et contrôler l'utilisation de nos services et de notre communication
•	Fournir aux utilisateurs des nouvelles, des offres spéciales, des bulletins d'information et des informations générales sur les biens et les services que nous proposons.

Intérêts légitimes : Nous nous appuyons sur des intérêts légitimes en estimant que le traitement est équitable et raisonnable et qu'il ne l'emporte pas sur vos intérêts ou vos libertés et droits fondamentaux. En particulier :

•	Placer des cookies et d'autres outils sur votre navigateur 
•	Développer de nouveaux services
•	pour maintenir et améliorer nos services, ainsi que pour détecter, prévenir et traiter les menaces à la sécurité

Nécessité de respecter les obligations légales : Respecter les obligations réglementaires et d'intérêt public. En particulier :

•	Pour vous informer des modifications apportées à nos services et à notre politique de protection de la vie privée
•	Respecter la réglementation et la législation en vigueur.
•	Pour l'application légale des réclamations et des droits


4	Conservation des données

Nous conservons les données à caractère personnel aussi longtemps qu'elles sont nécessaires aux fins pour lesquelles elles ont été collectées et conformément aux exigences légales et réglementaires ou aux dispositions contractuelles. À l'issue de cette période, nous supprimons ou rendons entièrement anonymes vos données à caractère personnel.


5	Destinataires des données

Nous faisons appel à des sociétés tierces ("prestataires de services") pour faciliter le fonctionnement de nos services, aider à analyser l'utilisation des services ou fournir des services nécessaires, tels que le paiement et la fourniture de services informatiques. Ces tiers n'ont accès à vos données personnelles que dans la mesure nécessaire à l'exécution de ces tâches. 

Type(s) de prestataires de services susceptibles d'accéder à vos données à caractère personnel :

•	Tiers fournisseurs de services informatiques et de logiciels
•	Tiers qui nous aident à mieux connaître nos clients et à faire du marketing


6	Transferts de données

Nous et/ou nos prestataires de services pouvons transférer et traiter vos données à caractère personnel dans les pays suivants :

•	UE et EEE
•	ÉTATS-UNIS

Nous pouvons faire appel à des prestataires de services situés en partie dans des pays dits tiers (en dehors de l'Union européenne ou de l'Espace économique européen ou de la Suisse) ou y traiter des données à caractère personnel, c'est-à-dire des pays dont le niveau de protection des données ne correspond pas à celui de l'UE ou de la Suisse.

Nous protégeons vos données personnelles conformément à nos obligations contractuelles et à la législation applicable en matière de protection des données lors du transfert de données à l'étranger. 

Ces garanties peuvent comprendre

•	le transfert vers des pays dont le Conseil fédéral estime qu'ils offrent un niveau de protection adéquat, ainsi que vers des pays ayant fait l'objet d'une décision d'adéquation de la part de la Commission européenne
•	Appliquer des clauses types de protection des données, des règles d'entreprise contraignantes ou d'autres obligations contractuelles standard qui assurent une protection appropriée des données.

Si un transfert vers un pays tiers a lieu et qu'il n'y a pas de décision d'adéquation ou de garanties appropriées, il est possible et il y a un risque que les autorités du pays tiers (par exemple les services de renseignement) puissent avoir accès aux données transférées et que le respect des droits de la personne concernée ne soit pas garanti.


7	Divulgation des données

Nous pouvons divulguer vos données personnelles si nous pensons en toute bonne foi qu'une telle action est nécessaire :

•	Pour se conformer à une obligation légale (c'est-à-dire si la loi l'exige ou en réponse à des demandes valables émanant d'autorités publiques, telles qu'un tribunal ou une agence gouvernementale).
•	pour protéger la sécurité de nos services et défendre nos droits ou notre propriété
•	prévenir ou enquêter sur d'éventuels actes répréhensibles en rapport avec nous


8	Sécurité des données

Nous prenons des mesures de sécurité techniques et organisationnelles raisonnables que nous jugeons appropriées pour protéger vos données stockées contre la manipulation, la perte ou l'accès non autorisé de tiers. Nos mesures de sécurité sont continuellement adaptées aux développements technologiques.

Nous prenons également très au sérieux la confidentialité des données internes. Nos employés et les prestataires de services que nous engageons sont tenus de garder le secret et de respecter la législation applicable en matière de protection des données. En outre, ils n'ont accès aux données à caractère personnel que dans la mesure où cela est nécessaire à l'exécution de leurs tâches ou de leur mandat respectifs.

La sécurité de vos données personnelles est importante pour nous, mais n'oubliez pas qu'aucune méthode de transmission sur l'internet ou de stockage électronique n'est sûre à 100 %. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos données personnelles, nous ne pouvons pas garantir leur sécurité absolue. Nous vous recommandons d'utiliser un logiciel antivirus, un pare-feu et d'autres logiciels similaires pour protéger votre système.


9	Vos droits

Vous disposez des droits ci-dessous en matière de protection des données. Pour exercer ces droits, vous pouvez contacter l'adresse ci-dessus ou envoyer un e-mail à : info@dooitapp.ch. Veuillez noter que nous pouvons vous demander de vérifier votre identité avant de répondre à de telles demandes.

•	Droit d'accès : Vous avez le droit de demander une copie de vos données à caractère personnel, que nous vous fournirons sous forme électronique.
•	Droit de modification : Vous avez le droit de nous demander de corriger nos dossiers si vous pensez qu'ils contiennent des informations incorrectes ou incomplètes à votre sujet.
•	Droit de retirer son consentement : Si vous avez donné votre consentement au traitement de vos données à caractère personnel, vous avez le droit de le retirer à tout moment avec effet pour l'avenir. Cela inclut les cas où vous souhaitez ne plus recevoir de communications marketing. Une fois que nous aurons reçu la notification du retrait de votre consentement, nous ne traiterons plus vos informations aux fins auxquelles vous avez initialement consenti, à moins qu'il n'existe une autre base juridique pour le traitement. Pour ne plus recevoir d'e-mails de notre part, veuillez cliquer sur le lien "se désinscrire" dans l'e-mail que vous avez reçu ou contactez-nous à l'adresse info@dooitapp.ch.
•	Droit à l'effacement : vous avez le droit de nous demander d'effacer vos données à caractère personnel lorsqu'elles ne sont plus nécessaires aux fins pour lesquelles elles ont été collectées ou lorsqu'elles ont été traitées de manière illicite.
•	Droit à la limitation du traitement : Vous avez le droit de demander la limitation du traitement de vos données à caractère personnel si vous estimez qu'elles sont inexactes, que notre traitement est illégal ou que nous n'avons plus besoin de les traiter pour la finalité initiale, mais que nous ne pouvons pas les effacer en raison d'une obligation légale ou parce que vous ne voulez pas que nous les effacions.
•	Droit à la portabilité : Vous avez le droit de demander que nous transmettions vos données à caractère personnel à un autre responsable du traitement dans un format standard tel qu'Excel, s'il s'agit de données que vous nous avez fournies et si nous les traitons sur la base juridique de votre consentement ou pour exécuter nos obligations contractuelles.
•	Droit d'opposition au traitement : Lorsque la base juridique de notre traitement de vos données à caractère personnel est notre intérêt légitime, vous avez le droit de vous opposer à ce traitement pour des raisons tenant à votre situation particulière. Nous nous conformerons à votre demande, sauf si nous disposons d'une base juridique impérieuse pour le traitement qui l'emporte sur vos intérêts ou si nous devons continuer à traiter les données à caractère personnel pour l'exercice ou la défense d'un droit en justice.
•	Droit de déposer une plainte auprès d'une autorité de contrôle : Vous avez le droit de faire appel à une autorité de contrôle de la protection des données si vous estimez que le traitement de vos données personnelles viole la loi sur la protection des données. L'autorité compétente en matière de protection des données en Suisse est le Préposé fédéral à la protection des données et à la transparence (www.edoeb.admin.ch/edoeb/en/home.html). Dans l'UE et l'EEE, vous pouvez exercer ce droit, par exemple, auprès d'une autorité de contrôle dans l'État membre de votre résidence, de votre lieu de travail ou du lieu de l'infraction présumée. Vous trouverez une liste des autorités compétentes à l'adresse suivante : https://edpb.europa.eu/about-edpb/board/members_en.


10	Médias sociaux et liens vers des applications et sites web tiers

Nos Services contiennent des liens vers des sites web ou des applis qui ne sont pas exploités par nous. Lorsque vous cliquez sur un lien tiers, vous serez dirigé vers le site web ou l'appli de ce tiers. Nous n'avons aucun contrôle sur le contenu, les politiques de confidentialité ou les pratiques des sites web ou services tiers.

Nous maintenons des présences en ligne sur les réseaux sociaux pour, entre autres, communiquer avec les clients et les clients potentiels et pour fournir des informations sur nos produits et services. Si vous avez un compte sur le même réseau, il est possible que les informations et les médias que vous y mettez à disposition soient vus par nous, par exemple lorsque nous accédons à votre profil. En outre, le réseau social peut nous permettre de vous contacter. Dès que nous transférons des données à caractère personnel dans notre propre système, nous en sommes responsables de manière indépendante. Il s'agit alors de mesures précontractuelles et de l'exécution d'un contrat. Pour connaître la base juridique du traitement des données effectué par les réseaux sociaux sous leur propre responsabilité, veuillez vous référer à leurs déclarations de protection des données. Vous trouverez ci-dessous une liste des réseaux sociaux sur lesquels nous sommes présents en ligne :

•	Facebook : Politique de confidentialité
•	Instagram : Politique de confidentialité


11	Bulletin d'information

Nous envoyons des bulletins d'information et d'autres notifications par courrier électronique et par d'autres canaux de communication et pouvons les diffuser avec l'aide de tiers.

En principe, vous devez consentir expressément à recevoir des bulletins d'information et d'autres notifications de notre part, à moins que cela ne soit autorisé pour d'autres raisons juridiques. 

Les lettres d'information et autres notifications peuvent contenir des liens web ou des pixels de suivi qui indiquent si une lettre d'information ou une notification individuelle a été ouverte et sur quels liens web on a cliqué (mesure de la performance). Ces liens web et pixels de suivi enregistrent l'utilisation des lettres d'information et autres notifications. Nous utilisons cet enregistrement statistique de l'utilisation, y compris la mesure du succès et de la portée, afin de pouvoir proposer des lettres d'information et d'autres notifications de manière efficace et conviviale, ainsi que de manière permanente, sûre et fiable, sur la base des habitudes de lecture des destinataires.

Vous pouvez à tout moment vous désabonner des lettres d'information et autres notifications et vous opposer ainsi notamment à la collecte d'utilisation susmentionnée. Vous pouvez le faire en nous contactant directement ou en suivant le lien inclus dans le pied de page de chaque newsletter que nous vous envoyons.


12	Modifications de la présente politique de protection de la vie privée

Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous encourageons donc à consulter régulièrement la présente politique de confidentialité pour prendre connaissance d'éventuels changements. 

Les modifications apportées à la présente politique de protection de la vie privée entrent en vigueur au moment où elles sont publiées sur cette page.


13	Contactez nous

Si vous avez des questions concernant cette politique de confidentialité, n'hésitez pas à nous contacter à l'adresse suivante : 

Dooit App Sàrl, 

ZA La Pièce 1-A5, A-One Business Center, 1180 Rolle,

info@dooitapp.ch.`,
  },
  de: {
    title: 'Datenschutzrichtlinie',
    content: `Datenschutzpolitik von Dooit App Sàrl

Version 1.0 - 08. August 2024

Über uns

In dieser Datenschutzrichtlinie ("Datenschutzrichtlinie") wird erläutert, wie wir Ihre personenbezogenen Daten verarbeiten und schützen, wenn Sie die Website von Dooit, die unter https://dooitapp.ch/ ("Website") verfügbar ist, oder die App von Dooit ("App"; die Website und die App werden zusammen als "Plattform" bezeichnet) oder unsere über die Plattform von Dooit bereitgestellten Dienste (zusammen die "Dienste") nutzen

Diese Dienste werden von Dooit App Sàrl, ZA La Pièce 1-A5, A-One Business Center, 1180 Rolle (das "Unternehmen", "wir", "unser" oder "uns") betrieben. Das Unternehmen ist der Verantwortliche für die unten beschriebene Datenverarbeitung.

Sofern in dieser Datenschutzerklärung nicht anders definiert, haben die in dieser Datenschutzerklärung verwendeten Definitionen dieselbe Bedeutung wie im Schweizerischen Bundesgesetz über den Datenschutz (DSG) oder in der Allgemeinen Datenschutzverordnung der EU (GDPR).


1	Personenbezogene Daten, die wir sammeln

Wenn Sie unsere Dienste nutzen, können wir personenbezogene Daten für eine Reihe von Zwecken im Zusammenhang mit unserem Geschäftsbetrieb erfassen oder erhalten, und zwar wie in den folgenden Punkten aufgeführt 1.1 und 1.2 unten 

Es besteht keine Verpflichtung, Ihre persönlichen Daten anzugeben. Bitte beachten Sie jedoch, dass unsere Dienstleistungen nicht erbracht werden können, wenn Sie die erforderlichen Daten nicht bereitstellen, die für die Erfüllung des Vertrags zwischen Ihnen und uns unbedingt erforderlich sind.

1.1	Auf der Website

•	Nutzungs- und Analysedaten (z. B. Kennungen, Anzahl der Klicks, Tracking-Daten)
•	Kontaktinformationen (z. B. Name, Adresse, Telefonnummer, Geburtsdatum)
•	Anfragedetails (z. B. Details und Inhalt Ihrer Anfragen)
•	Angaben zum Website-Besucher (z. B. IP-Adresse, Logfiles, Terminal-ID)

1.2	Auf der App

•	Nutzungs- und Analysedaten (z. B. Kennungen, Anzahl der Klicks, Tracking-Daten)
•	Kontaktinformationen (z. B. Name, Adresse, Telefonnummer, Geburtsdatum)
•	Geolokalisierung (z. B. Ihr tatsächlicher Standort, GPS-Daten)
•	Anmeldedaten (z. B. Passwort, Benutzername, Sitzung)
•	Anfragedetails (z. B. Details und Inhalt Ihrer Anfragen)
•	Chat-Details (z. B. Informationen, die über die Chat-Funktion der App übermittelt werden)
•	Details zu App-Besuchern (z. B. IP-Adresse, Logfiles, Terminal-ID)


2	Wie wir personenbezogene Daten sammeln

Wir sammeln Informationen über unsere Nutzer, wenn sie unsere Dienste nutzen, einschließlich der Durchführung bestimmter Aktionen innerhalb der Dienste.

Direkt

•	Über die Website und/oder die App und die elektronische Kommunikation
•	Wenn Sie unsere Dienste nutzen
•	Wenn Sie Dienstleistungen für uns erbringen
•	Wenn Sie mit uns auf elektronischem Wege über unsere Dienste korrespondieren
•	Wenn Sie bei der Nutzung unserer Dienste browsen, ein Formular ausfüllen oder eine Anfrage stellen

Indirekt

•	Durch öffentliche Quellen
•	Aus öffentlichen Registern (z. B. Handelsregistern), Zeitungsartikeln und Internetrecherchen
•	Wenn unsere Geschäftskunden uns mit der Erbringung professioneller Dienstleistungen beauftragen, bei denen sie persönliche Daten, die sie kontrollieren, an uns weitergeben, ist dies Teil dieses Auftrags.
•	Von externen Dienstanbietern (siehe Abschnitt 5)


3	Rechtsgrundlage und Zweck

Unsere Rechtsgrundlage für die Erhebung und Verwendung der in dieser Datenschutzrichtlinie beschriebenen personenbezogenen Daten hängt von den personenbezogenen Daten ab, die wir erheben, und von den spezifischen Zwecken, für die wir sie erheben.

3.1	Auf der Website 

Einverständnis: Wir können uns auf Ihre zum Zeitpunkt der Übermittlung Ihrer personenbezogenen Daten freiwillig erteilte Zustimmung stützen. Dies gilt insbesondere:

•	Zur Analyse, Verbesserung, Personalisierung und Überwachung der Nutzung unserer Dienste und Kommunikation
•	Platzierung nicht notwendiger Cookies und anderer Tools in Ihrem Browser
•	Zur Versorgung der Nutzer mit Neuigkeiten, Sonderangeboten, Newslettern und allgemeinen Informationen über die von uns angebotenen Waren und Dienstleistungen

Berechtigte Interessen: Wir stützen uns auf berechtigte Interessen, die auf unserer Einschätzung beruhen, dass die Verarbeitung fair und angemessen ist und Ihre Interessen oder Grundrechte und -freiheiten nicht überwiegen. Im Besonderen:

•	Um Cookies und andere Tools in Ihrem Browser zu platzieren 
•	Entwicklung neuer Dienstleistungen
•	Zur Aufrechterhaltung und Verbesserung unserer Dienste sowie zur Erkennung, Verhinderung und Bekämpfung von Sicherheitsbedrohungen

3.2	Auf der App

Vertrag: Zur Erfüllung unserer vertraglichen Verpflichtungen oder zur Durchführung von Maßnahmen im Zusammenhang mit einem Vertrag mit Ihnen. Im Besonderen:

•	Um Sie als Kunden zu unterstützen
•	Zum Einrichten und Verwalten Ihres Kontos sowie zum Überprüfen Ihrer Anmeldedaten beim Einloggen
•	Zur Bereitstellung unserer Dienstleistungen
•	Um die Bereitstellung der von den Dienstleistern in der App angebotenen Dienste zu ermöglichen 

Einverständnis: Wir können uns auf Ihre zum Zeitpunkt der Übermittlung Ihrer personenbezogenen Daten freiwillig erteilte Zustimmung stützen. Dies gilt insbesondere:

•	Zur Analyse, Verbesserung, Personalisierung und Überwachung der Nutzung unserer Dienste und Kommunikation
•	Zur Versorgung der Nutzer mit Neuigkeiten, Sonderangeboten, Newslettern und allgemeinen Informationen über die von uns angebotenen Waren und Dienstleistungen

Berechtigte Interessen: Wir stützen uns auf berechtigte Interessen, die auf unserer Einschätzung beruhen, dass die Verarbeitung fair und angemessen ist und Ihre Interessen oder Grundrechte und -freiheiten nicht überwiegen. Im Besonderen:

•	Cookies und andere Tools auf Ihrem Browser zu platzieren 
•	Entwicklung neuer Dienstleistungen
•	Zur Aufrechterhaltung und Verbesserung unserer Dienste sowie zur Erkennung, Verhinderung und Bekämpfung von Sicherheitsbedrohungen

Notwendigkeit zur Erfüllung rechtlicher Verpflichtungen: Zur Erfüllung gesetzlicher und im öffentlichen Interesse liegender Verpflichtungen. Im Besonderen:

•	Um Sie über Änderungen an unseren Dienstleistungen und unserer Datenschutzrichtlinie zu informieren
•	Einhaltung der geltenden Vorschriften und Gesetze.
•	Zur gerichtlichen Durchsetzung von Ansprüchen und Rechten


4	Vorratsspeicherung von Daten

Wir bewahren personenbezogene Daten so lange auf, wie sie für die Zwecke, für die sie erhoben wurden, und in Übereinstimmung mit gesetzlichen und regulatorischen Anforderungen oder vertraglichen Vereinbarungen benötigt werden. Nach Ablauf dieser Frist löschen wir Ihre personenbezogenen Daten oder anonymisieren sie vollständig.


5	Empfänger der Daten

Wir beauftragen Drittunternehmen ("Dienstleister"), um den Betrieb unserer Dienste zu erleichtern, bei der Analyse der Nutzung der Dienste zu helfen oder notwendige Dienstleistungen wie Zahlungen und die Bereitstellung von IT-Diensten zu erbringen. Diese Dritten haben nur insoweit Zugriff auf Ihre personenbezogenen Daten, als dies zur Erfüllung dieser Aufgaben erforderlich ist. 

Art(en) von Dienstleistern, die auf Ihre personenbezogenen Daten zugreifen können:

•	Dritte, die IT- und Softwaredienste anbieten
•	Dritte, die uns bei Kundeneinblicken und Marketing helfen


6	Datenübertragungen

Wir und/oder unsere Dienstleister können Ihre personenbezogenen Daten in die folgenden Länder übermitteln und dort verarbeiten:

•	EU und EWR
•	USA

Wir können Dienstleister einsetzen, die teilweise in so genannten Drittländern (außerhalb der Europäischen Union bzw. des Europäischen Wirtschaftsraums oder der Schweiz) ansässig sind oder dort personenbezogene Daten verarbeiten, d.h. in Ländern, deren Datenschutzniveau nicht dem der EU oder der Schweiz entspricht.

Wir schützen Ihre personenbezogenen Daten gemäß unseren vertraglichen Verpflichtungen und den geltenden Datenschutzgesetzen, wenn wir Daten ins Ausland übermitteln. 

Diese Schutzmaßnahmen können Folgendes umfassen:

•	Die Übermittlung in Länder, die nach Ansicht des Bundesrates ein angemessenes Schutzniveau bieten, sowie in Länder, für die ein Angemessenheitsbeschluss der Europäischen Kommission vorliegt
•	Anwendung von Standard-Datenschutz-Musterklauseln, verbindlichen Unternehmensregeln oder anderen Standard-Vertragsverpflichtungen, die einen angemessenen Datenschutz gewährleisten

Wenn eine Übermittlung in ein Drittland erfolgt und kein Angemessenheitsbeschluss oder geeignete Garantien vorliegen, besteht die Möglichkeit und das Risiko, dass Behörden in dem Drittland (z. B. Nachrichtendienste) Zugang zu den übermittelten Daten erhalten und dass die Durchsetzbarkeit der Rechte der betroffenen Person nicht gewährleistet werden kann.


7	Offenlegung von Daten

Wir können Ihre personenbezogenen Daten weitergeben, wenn wir in gutem Glauben davon ausgehen, dass eine solche Maßnahme erforderlich ist:

•	Um einer rechtlichen Verpflichtung nachzukommen (d. h. wenn dies gesetzlich vorgeschrieben ist oder als Antwort auf berechtigte Anfragen von Behörden, wie z. B. einem Gericht oder einer Regierungsbehörde)
•	Um die Sicherheit unserer Dienste zu schützen und unsere Rechte oder unser Eigentum zu verteidigen
•	Um mögliches Fehlverhalten in Verbindung mit uns zu verhindern oder zu untersuchen

8	Datensicherheit

Wir setzen angemessene technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre gespeicherten Daten gegen Manipulation, Verlust oder unberechtigten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend angepasst.

Auch den internen Datenschutz nehmen wir sehr ernst. Unsere Mitarbeiter und die von uns beauftragten Dienstleister sind zur Verschwiegenheit und zur Einhaltung der geltenden Datenschutzgesetze verpflichtet. Darüber hinaus erhalten sie nur insoweit Zugang zu personenbezogenen Daten, als dies für die Erfüllung ihrer jeweiligen Aufgaben oder ihres Auftrags erforderlich ist.

Die Sicherheit Ihrer persönlichen Daten ist uns wichtig, aber denken Sie daran, dass keine Methode der Übertragung über das Internet oder der elektronischen Speicherung 100% sicher ist. Auch wenn wir uns bemühen, Ihre personenbezogenen Daten mit wirtschaftlich vertretbaren Mitteln zu schützen, können wir ihre absolute Sicherheit nicht garantieren. Wir empfehlen die Verwendung von Antiviren-Software, einer Firewall und anderer ähnlicher Software zum Schutz Ihres Systems.

9	Ihre Rechte

Sie haben die folgenden Datenschutzrechte. Um diese Rechte auszuüben, können Sie sich an die oben genannte Adresse wenden oder eine E-Mail senden an: info@dooitapp.ch. Bitte beachten Sie, dass wir Sie möglicherweise auffordern, Ihre Identität zu überprüfen, bevor wir auf solche Anfragen reagieren.

•	Recht auf Zugang: Sie haben das Recht, eine Kopie Ihrer personenbezogenen Daten anzufordern, die wir Ihnen in elektronischer Form zur Verfügung stellen werden.
•	Recht auf Änderung: Sie haben das Recht, uns zu bitten, unsere Aufzeichnungen zu korrigieren, wenn Sie glauben, dass sie falsche oder unvollständige Informationen über Sie enthalten.
•	Recht auf Widerruf der Einwilligung: Wenn Sie in die Verarbeitung Ihrer personenbezogenen Daten eingewilligt haben, haben Sie das Recht, diese Einwilligung jederzeit mit Wirkung für die Zukunft zu widerrufen. Dies gilt auch für den Fall, dass Sie sich von Marketingmitteilungen abmelden möchten. Sobald wir die Mitteilung erhalten haben, dass Sie Ihre Einwilligung zurückgezogen haben, werden wir Ihre Daten nicht mehr für den/die Zweck(e) verarbeiten, dem/denen Sie ursprünglich zugestimmt haben, es sei denn, es gibt eine andere Rechtsgrundlage für die Verarbeitung. Um keine E-Mails mehr von uns zu erhalten, klicken Sie bitte auf den Link "Abmelden" in der E-Mail, die Sie erhalten haben, oder kontaktieren Sie uns unter info@dooitapp.ch.
•	Recht auf Löschung: Sie haben das Recht, von uns die Löschung Ihrer personenbezogenen Daten zu verlangen, wenn diese für die Zwecke, für die sie erhoben wurden, nicht mehr erforderlich sind oder wenn sie unrechtmäßig verarbeitet wurden.
•	Recht auf Einschränkung der Verarbeitung: Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, wenn Sie der Ansicht sind, dass die Daten unrichtig sind, die Verarbeitung unrechtmäßig ist oder wir die Daten für den ursprünglichen Zweck nicht mehr benötigen, wir sie aber aufgrund einer gesetzlichen Verpflichtung nicht löschen können oder Sie die Löschung nicht wünschen.
•	Recht auf Übertragbarkeit: Sie haben das Recht, von uns zu verlangen, dass wir Ihre personenbezogenen Daten in einem Standardformat wie Excel an einen anderen für die Datenverarbeitung Verantwortlichen übermitteln, wenn es sich um Daten handelt, die Sie uns zur Verfügung gestellt haben und wenn wir sie auf der Rechtsgrundlage Ihrer Einwilligung oder zur Erfüllung unserer vertraglichen Verpflichtungen verarbeiten.
•	Recht auf Widerspruch gegen die Verarbeitung: Wenn die Rechtsgrundlage für die Verarbeitung Ihrer personenbezogenen Daten unser berechtigtes Interesse ist, haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, Widerspruch gegen diese Verarbeitung einzulegen. Wir werden Ihrem Wunsch nachkommen, es sei denn, wir haben eine zwingende Rechtsgrundlage für die Verarbeitung, die Ihre Interessen überwiegt, oder wir müssen die personenbezogenen Daten für die Ausübung oder Verteidigung eines Rechtsanspruchs weiterverarbeiten.
•	Recht auf Beschwerde bei einer Aufsichtsbehörde: Sie haben das Recht, sich bei einer Datenschutzaufsichtsbehörde zu beschweren, wenn Sie der Meinung sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen das Datenschutzrecht verstößt. Die zuständige Datenschutzbehörde in der Schweiz ist der Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte (www.edoeb.admin.ch/edoeb/en/home.html). In der EU und im EWR können Sie dieses Recht zum Beispiel bei einer Aufsichtsbehörde im Mitgliedstaat Ihres Wohnsitzes, Ihres Arbeitsplatzes oder des Ortes des mutmaßlichen Verstoßes geltend machen. Eine Liste der zuständigen Behörden finden Sie hier: https://edpb.europa.eu/about-edpb/board/members_en.


10	Soziale Medien und Links zu Apps und Websites von Drittanbietern

Unsere Dienste enthalten Links zu Websites oder Apps, die nicht von uns betrieben werden. Wenn Sie auf einen Link eines Drittanbieters klicken, werden Sie auf die Website oder App dieses Drittanbieters weitergeleitet. Wir haben keine Kontrolle über den Inhalt, die Datenschutzrichtlinien oder die Praktiken von Websites oder Diensten Dritter.

Wir unterhalten Online-Präsenzen in sozialen Netzwerken, um u. a. mit Kunden und Interessenten zu kommunizieren und Informationen über unsere Produkte und Dienstleistungen bereitzustellen. Wenn Sie ein Konto bei demselben Netzwerk haben, ist es möglich, dass Ihre dort zur Verfügung gestellten Informationen und Medien von uns gesehen werden können, wenn wir beispielsweise auf Ihr Profil zugreifen. Darüber hinaus kann uns das soziale Netzwerk die Möglichkeit geben, mit Ihnen in Kontakt zu treten. Sobald wir personenbezogene Daten in unser eigenes System übertragen, sind wir für diese selbst verantwortlich. Dies geschieht dann zur Durchführung vorvertraglicher Maßnahmen und zur Erfüllung eines Vertrages. Die Rechtsgrundlagen der Datenverarbeitung, die die sozialen Netzwerke in eigener Verantwortung vornehmen, entnehmen Sie bitte deren Datenschutzerklärungen. Nachfolgend finden Sie eine Auflistung der sozialen Netzwerke, in denen wir eine Online-Präsenz betreiben:

•	Facebook: Datenschutzbestimmungen
•	Instagram: Datenschutzbestimmungen


11	Newsletter

Wir versenden Newsletter und andere Benachrichtigungen per E-Mail und über andere Kommunikationskanäle und können diese mit Hilfe von Dritten zustellen.

Grundsätzlich müssen Sie dem Erhalt von Newslettern und anderen Benachrichtigungen von uns ausdrücklich zustimmen, es sei denn, dies ist aus anderen rechtlichen Gründen zulässig. 

Newsletter und andere Benachrichtigungen können Weblinks oder Zählpixel enthalten, die aufzeichnen, ob ein einzelner Newsletter oder eine Benachrichtigung geöffnet wurde und welche Weblinks angeklickt wurden (Leistungsmessung). Solche Weblinks und Zählpixel zeichnen die Nutzung von Newslettern und anderen Benachrichtigungen auf. Diese statistische Erfassung der Nutzung, einschließlich der Erfolgs- und Reichweitenmessung, verwenden wir, um Newsletter und andere Benachrichtigungen effektiv und nutzerfreundlich sowie dauerhaft, sicher und zuverlässig auf der Grundlage der Lesegewohnheiten der Empfänger anbieten zu können.

Sie können sich jederzeit von Newslettern und anderen Benachrichtigungen abmelden und damit insbesondere der vorgenannten Nutzungserfassung widersprechen. Dies können Sie tun, indem Sie sich direkt an uns wenden oder dem Link in der Fußzeile jedes Newsletters folgen, den wir Ihnen zusenden.


12	Änderungen an dieser Datenschutzrichtlinie

Wir können unsere Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Wir empfehlen Ihnen daher, diese Datenschutzrichtlinie regelmäßig auf Änderungen zu überprüfen. 

Änderungen an dieser Datenschutzrichtlinie treten in Kraft, wenn sie auf dieser Seite veröffentlicht werden.


13	Kontakt

Wenn Sie Fragen zu dieser Datenschutzerklärung haben, zögern Sie nicht, sich mit uns in Verbindung zu setzen: 

Dooit App Sàrl, 

ZA La Pièce 1-A5, A-One Business Center, 1180 Rolle,

info@dooitapp.ch.`,
  },
}
