import { api } from '@/app'

const BASE_URL = 'crm/contacts/'

type Data = {
  name: string
  email: string
  message: string
}

export async function sendContact(data: Data) {
  const response = await api.post(BASE_URL, data)

  return response.data
}
