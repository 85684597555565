export type FirebaseInstance = any

let initialized = false

type FirebaseFunction<T> = (fb: FirebaseInstance) => T

/*
  Firebase does not work in a server environment as it requires access to the browser's fetch API
  This is a workaround to allow us to use Firebase in the browser without failing Gatbsy's build process
*/

const config = {
  apiKey: 'AIzaSyCEJCuialywa7QcTU8Bx62d-d82L5DQI7U',
  authDomain: 'x-dooit.firebaseapp.com',
  projectId: 'x-dooit',
  storageBucket: 'x-dooit.appspot.com',
  messagingSenderId: '601982618726',
  appId: '1:601982618726:web:dc4335b3e41b3c6b0e71a7',
  measurementId: 'G-CJ179VG33W',
}

export let cachedFirebaseInstance: FirebaseInstance = null

export async function getFirebase() {
  const fbApp = await import('firebase/compat/app')
  await import('firebase/compat/auth')
  await import('firebase/compat/analytics')

  const _firebase = fbApp.default

  if (!initialized) {
    _firebase.initializeApp(config)
    cachedFirebaseInstance = _firebase
    initialized = true
  }

  return _firebase
}

export async function getFirebaseAuth() {
  const firebaseAuth = (await getFirebase()).auth
  return firebaseAuth
}
