import {
  AppImages,
  assignTextStyle,
  I18N,
  LARGE_BREAKPOINT,
  MID_BREAKPOINT,
  MOBILE_BREAKPOINT,
  navigation,
  variantProvider,
} from '@/app'
import { CenterWrapper, Image, Text, View, Button } from '@/components'

const scrollToContent = () => {
  const element = document.getElementById('section2')
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

export function GetStartedSection() {
  return (
    <CenterWrapper
      variants={['sectionsPaddings']}
      styles={{
        wrapper: styles.centerWrapper,
        innerWrapper: styles.centerInnerWrapper,
      }}>
      <View css={styles.innerWrapper}>
        <Text css={styles.title}>
          {I18N.t('homeSections.getStarted.title1')}
          {'\u00A0'}
          <Text css={[styles.title, styles['title:primary3']]} text={I18N.t('homeSections.getStarted.title2')} />
          {'\u00A0'}
          {I18N.t('homeSections.getStarted.title3')}
        </Text>
        <Text css={styles.content} text={I18N.t('homeSections.getStarted.description')} />
        <View css={styles.buttonWrapper}>
          <Button
            debugName='get-started-button'
            text={I18N.t('getStarted')}
            variants={['text:neutral1']}
            css={styles.button}
            onPress={scrollToContent}
          />
          <Button
            debugName='learn-more-button'
            text={I18N.t('learnMore')}
            variants={['outline']}
            css={styles.button}
            onPress={() => navigation.navigate('About')}
          />
        </View>
      </View>
      <Image css={styles.image} source={AppImages.GetStartedSection} objectFit='contain' />
    </CenterWrapper>
  )
}

const styles = variantProvider.createComponentStyle(
  theme => ({
    centerWrapper: {
      backgroundImage: `url(${AppImages.Section1Bg})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right',

      [theme.media.down(LARGE_BREAKPOINT)]: {
        backgroundPosition: 'right 100px',
      },

      [theme.media.down(MID_BREAKPOINT)]: {
        backgroundSize: 'contain',
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        backgroundSize: 'cover',
        backgroundPosition: 'unset',
        backgroundImage: `url(${AppImages.Section1BgMobile})`,
      },
    },
    centerInnerWrapper: {
      ...theme.presets.row,
      ...theme.presets.justifySpaceBetween,

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.presets.column,
        ...theme.spacing.paddingVertical(5),
        ...theme.spacing.paddingHorizontal(2),
        ...theme.spacing.gap(5),
      },
    },
    innerWrapper: {
      ...theme.presets.column,
      ...theme.spacing.gap(4),
      ...theme.presets.center,
    },

    image: {
      width: '45%',

      [theme.media.down(LARGE_BREAKPOINT)]: {
        width: '50%',
      },

      [theme.media.down(MID_BREAKPOINT)]: {
        width: '40%',
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.presets.fullWidth,
      },
    },

    buttonWrapper: {
      ...theme.presets.row,
      ...theme.spacing.gap(3),
      ...theme.presets.justifyStart,
      ...theme.presets.fullWidth,

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.presets.column,
        ...theme.spacing.gap(2),
        ...theme.presets.center,
        ...theme.spacing.marginTop(1),
      },
    },

    button: {
      height: theme.spacing.value(6),
      borderRadius: theme.spacing.value(2),
      ...theme.spacing.paddingHorizontal(4),

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        width: theme.spacing.value(20),
      },
    },

    title: {
      ...assignTextStyle('hx')(theme).text,
      display: 'inline-block',

      '&:nth-word(2)': {
        color: theme.colors.primary3,
      },

      [theme.media.down(LARGE_BREAKPOINT)]: {
        ...assignTextStyle('h0')(theme).text,
      },

      [theme.media.down(MID_BREAKPOINT)]: {
        ...assignTextStyle('h1')(theme).text,
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...assignTextStyle('h0')(theme).text,
        ...theme.presets.textCenter,
      },
    },

    'title:primary3': {
      color: theme.colors.primary3,

      [theme.media.down(LARGE_BREAKPOINT)]: {
        color: theme.colors.primary3,
      },
    },

    content: {
      ...assignTextStyle('h3')(theme).text,

      [theme.media.down(LARGE_BREAKPOINT)]: {
        ...assignTextStyle('h4')(theme).text,
        lineHeight: `${theme.spacing.value(3)}px`,
      },

      [theme.media.down(MID_BREAKPOINT)]: {
        ...assignTextStyle('h5')(theme).text,
        lineHeight: `${theme.spacing.value(2.5)}px`,
      },

      [theme.media.down(MOBILE_BREAKPOINT)]: {
        ...theme.presets.textCenter,
      },
    },
  }),
  true,
)
