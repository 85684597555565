import { navigation } from '@/app'
import { LinkStyles } from '@/app/stylesheets/Link'
import { ComponentVariants, PropsOf, TypeGuards, useDefaultComponentStyle } from '@codeleap/common'
import { scrollToElem, stopPropagation } from '@codeleap/web'
import { Link as GatsbyLink } from 'gatsby'
import { Link as GatsbyI18nLink } from 'gatsby-plugin-react-i18next'

type GatsbyLinkProps = PropsOf<typeof GatsbyLink>

export type LinkProps<T extends AppRoute> =
  ComponentVariants<typeof LinkStyles> & 
  Omit<GatsbyLinkProps, 'to'> & 
  {
    openNewTab?: boolean
    onScroll?: (to: string) => any
    to?: string
    route?: T
    routeParams?: Record<RouteParams<T>, string>
    text?: string
    type?: 'default' | 'i18n'
  }

export function Link<T extends AppRoute>(linkProps: LinkProps<T>) {
  const { 
    to: linkTo, 
    text, 
    route = null, 
    routeParams,
    openNewTab, 
    onScroll = null, 
    responsiveVariants, 
    children, 
    variants, 
    type = 'default', 
    ...props 
  } = linkProps

  const to = TypeGuards.isString(route) ? navigation.getPathWithParams(route, routeParams) : linkTo

  const isExternal = ['http', 'tel', 'mailto'].some((start) => to?.startsWith(start))

  function handleClick(event: React.MouseEvent) {
    if (to) {
      if (to.startsWith('#')) {
        event.preventDefault()
        stopPropagation(event)
        if (onScroll) {
          onScroll(to)
          return
        }
        scrollToElem(to)
      }
      if (openNewTab) {
        event.preventDefault()
        window?.open?.(to, '_blank')
        return false
      }
    }
  }

  const variantStyles = useDefaultComponentStyle<'u:Link', typeof LinkStyles>('u:Link', {
    responsiveVariants,
    variants,
    rootElement: 'anchor',
  })

  const content = children || text

  if (isExternal) {
    return <a
      href={to}
      onClick={handleClick}
      css={[variantStyles.text, variantStyles.anchor]}
      {...props}
    >
      {content}
    </a>
  }

  const _Link = type === 'default' ? GatsbyLink : GatsbyI18nLink

  return (
    <_Link
      // @ts-ignore
      ref={linkProps.ref}
      to={to}
      css={[variantStyles.text, variantStyles.anchor]}
      onClick={handleClick}
      {...props}
    >
      {content}
    </_Link>
  )
}
