import { I18N, React } from '@/app'
import { View, Link } from '@/components'
import { StylesOf } from '@codeleap/common'
import { HeaderComposition } from '../../app/stylesheets/Header'
import { NavigationUtils } from '@/utils'

export type NavContentProps = {
  isMobile?: boolean
  variantStyles?: StylesOf<HeaderComposition>
}

type NavigateLink = {
  text: string
  route?: AppRoute
  to?: string
}

const links: NavigateLink[] = [
  { text: I18N.t('header.home'), route: 'Home' },
  { text: I18N.t('header.about'), route: 'About' },
  { text: I18N.t('header.contact'), route: 'Contact' },
]

export const NavContent = (props: NavContentProps) => {
  const { variantStyles } = props

  const renderLink = React.useCallback((link: NavigateLink) => {
    const isSelected = NavigationUtils.isCurrentRoute(link?.route)

    return (
      <Link
        key={'header-' + link?.text}
        text={link?.text}
        route={link?.route}
        to={link?.to}
        css={[variantStyles?.navItem, isSelected ? variantStyles['navItem:selected'] : {}]}
      />
    )
  }, [])

  return (
    <View css={variantStyles.navContentWrapper}>
      {links?.map(renderLink)}
    </View>
  )
}
