import React from 'react'
import { Button, Text, View } from '@/components'
import { AnyFunction } from '@codeleap/common'

type VerifyEmailBeforeForm = {
  resendEmailConfirmation: AnyFunction
  cancelVerification: AnyFunction
}

export const VerifyEmailBeforeForm = ({ resendEmailConfirmation, cancelVerification }) => {
  const openEmail = () => {
    window.open('mailto:', '_blank', 'noopener,noreferrer')
  }

  const onSendEmail = () => {
    resendEmailConfirmation()
  }

  return (
    <View variants={['flex', 'column']}>
      <Text
        variants={['h1', 'extraBold', 'textCenter', 'marginBottom:4']}
        text={'Verify your email address'}
      />

      <Text
        variants={['p1', 'marginBottom:4', 'marginTop:-3', 'textCenter']}
        text={`Click the link in the email to confirm your address. Don't forget to check your spam folder.`}
      />

      <View variants={['column', 'gap:2', 'marginTop:auto']}>
        <View variants={['row', 'gap:1']}>
          <Button
            text={'Open your email'}
            onPress={openEmail}
            debugName={'Open email'}
            variants={['marginHorizontal:auto', 'fullWidth', 'large']}
          />
          <Button
            text={'Cancel'}
            onPress={cancelVerification}
            debugName={'Cancel email verify'}
            variants={['marginHorizontal:auto', 'fullWidth', 'large', 'outline']}
          />
        </View>

        <Button onPress={onSendEmail} debugName={'Resend verification'} variants={['minimal', 'large', 'row', 'gap:1', 'alignCenter', 'justifyCenter']}>
          <Text text={"Didn't receive?"} variants={['medium', 'alignSelfCenter', 'row', 'color:neutral10']} />
          <Text text={'Resend email'} variants={['medium', 'bold', 'color:primary3']} />
        </Button>
      </View>
    </View>
  )
}
