import { LoginForm } from './LoginForm'
import { SignupForm } from './SignupForm'
import { ForgotPasswordBeforeForm } from './ForgotPasswordBeforeForm'
import { ForgotPasswordAfterForm } from './ForgotPasswordAfterForm'
import { VerifyEmailBeforeForm } from './VerifyEmailBeforeForm'
import { VerifyEmailAfterForm } from './VerifyEmailAfterForm'

export const AuthForms = {
  LoginForm,
  SignupForm,
  ForgotPasswordBeforeForm,
  ForgotPasswordAfterForm,
  VerifyEmailBeforeForm,
  VerifyEmailAfterForm,
}
